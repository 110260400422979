<template>
  <v-card
    flat
    min-width="100"
  >
    <div
      v-if="pilotPhoneNumber"
      class="text-center"
    >
      <span>Pilot:</span>
      {{ pilotPhoneNumber }}
    </div>
    <div
      v-if="exploitants.length > 1"
      class="mx-4 my-2 font-italic"
    >
      {{ texts.manyExploitants }}
    </div>
    <div
      v-for="(exploitant, index) in exploitants"
      :key="index"
      class="px-2 subtitle-1 text-center"
    >
      <div>
        {{ exploitant.commercial_name }}
        {{ exploitant.phone_number }}
      </div>
    </div>
    <div
      v-if="altitudeM"
      class="px-1 pt-1 text-center"
    >
      <span>
        {{ altitudeM }} m / {{ altitudeF }} ft AMSL
      </span>
      <v-icon color="primary">
        {{ verticalDirectionIcon }}
      </v-icon>
    </div>
    <div
      v-if="speed"
      class="text-center"
    >
      {{ speed }} km / h
    </div>
    <div v-if="isUserAuthority">
      <div class="d-flex justify-center">
        <v-btn
          v-if="approvalIdentifier"
          depressed
          small
          class="mt-1 px-3 white--text"
          :class="approvalStatus"
          @click="selectApprovalAtClick()"
        >
          {{ approvalIdentifier }}
        </v-btn>
      </div>
      <div
        v-if="trackingAlertNames.length"
        class="px-1 mt-4 d-flex error--text"
      >
        <v-icon
          size="27"
          color="error"
        >
          mdi-shield-alert-outline
        </v-icon>
        <ul>
          <li
            v-for="(trackingAlertName, index) in trackingAlertNames"
            :key="index"
          >
            {{ trackingAlertName }}
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="isUserDronist && livestream"
      class="d-flex justify-center"
    >
      <v-btn
        depressed
        small
        class="mt-1 px-3 white--text"
        :class="approvalStatus"
        @click.prevent="setLivestreamPlaying()"
      >
        <v-icon
          color="red"
          class="pr-1"
          small
        >
          mdi-record
        </v-icon>
        <span v-translate>Watch live</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue';

import store from '@/store/';

import {
  GET_APPROVAL_DETAILS_NS,
  SET_APPROVAL_SELECTED_NS,
  RESET_FILTERS_NS,
} from '@/store/approvals';
import { SET_LIVESTREAM_PLAYING_NS } from '@/store/livestreams';

export default Vue.extend({
  store,
  props: { id: String },
  data() {
    return { unsubscribe: null };
  },
  computed: {
    texts() {
      return { manyEploitants: this.$gettext('Many exploitants have a drone related to this remote id:') };
    },
    isUserDronist() {
      return this.$store.getters['authentication/isUserDronist'];
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    currentPosition() {
      const positions = this.$store.state.map.trackingPositions;
      return positions.find((p) => p.tracker_id === this.id);
    },
    trackingAlerts() {
      const { trackingAlerts } = this.$store.state.map;
      const concernedAlerts = trackingAlerts.filter(
        (trackingAlert) => this.isTrackingAlertLinkToCurrentPosition(trackingAlert),
      ).filter((trackingAlert) => trackingAlert.tracker_alert?.type !== 'drone_detected');
      return concernedAlerts;
    },
    trackingAlertNames() {
      const names = this.trackingAlerts.map((trackingAlert) => this.getAlertTitle(trackingAlert));
      const uniqueNames = new Set(names);
      return [...uniqueNames];
    },
    concernedApproval() {
      let approval;
      if (this.isUserAuthority && this.currentPosition.approvals?.length) {
        this.currentPosition.approvals.forEach((d) => {
          if (this.$store.state.structures.items.find((s) => s.id === d.structure_id)) {
            approval = d;
          }
        });
      }
      if (this.isUserDronist && this.currentPosition.approvals?.length) {
        approval = this.currentPosition.approvals.at(0);
      }
      return approval;
    },
    exploitants() {
      let exploitants = this.currentPosition?.exploitants || [];
      if (this.concernedApproval) {
        exploitants = exploitants.filter(
          (exploitant) => exploitant.id === this.concernedApproval.exploitant_id,
        );
      }
      return exploitants;
    },
    altitudeM() {
      return this.currentPosition?.altitude || null;
    },
    altitudeF() {
      return this.altitudeM ? Math.round(this.altitudeM * 3.2808) : null;
    },
    speed() {
      return this.currentPosition?.speed || '';
    },
    pilotPhoneNumber() {
      return this.concernedApproval?.pilot_phone_number || '';
    },
    approvalIdentifier() {
      return this.concernedApproval?.approval_identifier || '';
    },
    approvalId() {
      return this.concernedApproval?.approval_id || null;
    },
    approvalStatus() {
      return this.concernedApproval?.approval_status || '';
    },
    verticalDirectionIcon() {
      const verticalDirection = this.currentPosition.direction_z;
      switch (verticalDirection) {
        case 'up':
          return 'mdi-arrow-up';
        case 'down':
          return 'mdi-arrow-down';
        default:
          return 'mdi-minus';
      }
    },
    concernedDroneId() {
      return this.currentPosition.drone_id;
    },
    concernedDrone() {
      const { drones } = this.$store.state.drones;
      return drones.find((d) => d.id === this.concernedDroneId);
    },
    showLivestreamfeture() {
      return (!this.$store.getters['exploitants/hideLivestreamsFeature']);
    },
    livestream() {
      if (this.concernedDrone && this.showLivestreamfeture) {
        const livestreams = this.$store.getters['livestreams/currentLivestreams'];
        return livestreams.find((s) => s.drone_id === this.concernedDroneId);
      }
      return undefined;
    },
  },
  methods: {
    async selectApprovalAtClick() {
      try {
        await this.selectApproval();
      } catch (error) {
        // If not found we need to reset filters
        this.$store.dispatch(RESET_FILTERS_NS);
        this.unsubscribe = this.$store.subscribe((mutation) => {
          if (mutation.type === 'approvals/SET_APPROVALS_COLLECTION_LOADED') {
            this.selectApproval();
          }
        });
      }
    },
    async selectApproval() {
      if (this.approvalId) {
        await this.$store.dispatch(GET_APPROVAL_DETAILS_NS, { approvalId: this.approvalId });
        this.$store.dispatch(SET_APPROVAL_SELECTED_NS, this.approvalId);
        if (this.unsubscribe) {
          this.unsubscribe();
        }
      }
    },
    setLivestreamPlaying() {
      this.$store.commit(SET_LIVESTREAM_PLAYING_NS, this.livestream.id);
    },
    isTrackingAlertLinkToCurrentPosition(trackingAlert) {
      let trackerIds;
      if (trackingAlert.alert_type === 'proximity') {
        trackerIds = trackingAlert.proximity_alert.trackers;
      } else {
        trackerIds = [trackingAlert.tracker_alert.tracker_id];
      }
      trackerIds = trackerIds.map((id) => `tracker-${id}`);
      return trackerIds.includes(this.id);
    },
    getAlertTitle(trackingAlert) {
      if (trackingAlert.alert_type === 'proximity') return this.$gettext('Proximity alert');

      const { tracker_alert: trackerAlert } = trackingAlert;
      switch (trackerAlert.type) {
        case 'unknown_approval':
          return this.$gettext('Unknown approval');
        case 'area':
          return this.$gettext('Outside of authorized area');
        case 'height':
          return this.$gettext('Height authorized exceeded');
        default:
          return this.$gettext('Unknown drone');
      }
    },
  },
});
</script>

<style
  lang="scss"
  scoped
>
.tracking .mapboxgl-popup-tip {
  display: none;
}
::v-deep .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  &.submitted {
    background-color: $submitted-color;
  }
  &.incomplete {
    background-color: $incomplete-color;
  }
  &.refused {
    background-color: $refused-color;
  }
  &.reserves {
    background-color: $reserves-color;
  }
  &.accepted {
    background-color: $accepted-color;
  }
  &.pending {
    background-color: $pending-color;
  }
}

</style>
