/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import { UPDATE_FLIGHT_ID_DISCUSSION_OPEN_NS } from '@/store/flights';

import DronistFlightNotification from '@/components/Header/notifications/DronistFlightNotification';

/**
 * DronistFlightMessageNotification class derived from DronistFlightNotification,
 * represent a notification about an new flight message.
 *
 * @class DronistFlightMessageNotification
 * @extends {DronistFlightNotification}
 */
class DronistFlightMessageNotification extends DronistFlightNotification {
  async atClick(dispatchStoreAction, subscribeStoreMutation) {
    await super.atClick(dispatchStoreAction, subscribeStoreMutation);
    subscribeStoreMutation({
      mutationName: 'flights/SET_FLIGHT_DETAILS',
      callback: this.openDiscussionPanel,
      params: { dispatchStoreAction, that: this },
    });
  }

  openDiscussionPanel({ dispatchStoreAction, that }) {
    dispatchStoreAction(UPDATE_FLIGHT_ID_DISCUSSION_OPEN_NS, that._flightId);
  }
}

export default DronistFlightMessageNotification;
