export default function makeActivationListingHeaders(gettext) {
  const statusHeader = {
    text: gettext('Statut'),
    value: 'status',
  };
  const identifierHeader = {
    text: gettext('N°'),
    value: 'identifier',
    class: 'bold',
  };
  const companyHeader = {
    text: gettext('Applicant'),
    value: 'company_name',
  };
  const communeHeader = {
    text: gettext('Town'),
    value: 'commune',
  };
  const periodHeader = {
    text: gettext('Period'),
    value: 'date_start',
  };
  const hourHeader = {
    text: gettext('Hour'),
    value: 'hour',
    sortable: false,
  };
  const maxHeightHeader = {
    text: gettext('Max flying height'),
    value: 'max_height',
    sortable: false,
  };
  const maxAltitudeHeader = {
    text: gettext('Max altitude'),
    value: 'max_altitude',
    sortable: false,
  };
  const qdrHeader = {
    text: gettext('QDR'),
    value: 'qdr',
    sortable: false,
  };
  const actionsHeader = {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  };
  const categoryHeader = {
    text: '',
    value: 'category_name',
  };
  const unreadMessageHeader = {
    text: '',
    value: 'unread_message_for_authority',
  };

  return {
    smallHeaders: [
      { ...statusHeader, width: '25%' },
      { ...identifierHeader, width: '75%' },
    ],
    mediumHeaders: [
      { ...statusHeader, width: '8%' },
      { ...identifierHeader, width: '14%' },
      { ...companyHeader, width: '26%' },
      { ...categoryHeader, width: '16%' },
      { ...periodHeader, width: '30%' },
      { ...unreadMessageHeader, width: '2%' },
      { ...actionsHeader, width: '4%' },
    ],
    bigHeaders: [
      { ...statusHeader, width: '5%' },
      { ...identifierHeader, width: '10%' },
      { ...companyHeader, width: '12%' },
      { ...categoryHeader, width: '6%' },
      { ...communeHeader, width: '10%' },
      { ...periodHeader, width: '14%' },
      { ...hourHeader, width: '9%' },
      { ...maxHeightHeader, width: '10%' },
      { ...maxAltitudeHeader, width: '10%' },
      { ...qdrHeader, width: '10%' },
      { ...unreadMessageHeader, width: '2%' },
      { ...actionsHeader, width: '2%' },
    ],
  };
}
