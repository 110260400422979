<template>
  <div
    :class="[getStatusClass, { 'mr-2': !isMobileBreakpoint }]"
    class="flight__status flight__container pa-3"
  >
    <!-- Header -->
    <v-row
      align="center"
      class="mb-3"
    >
      <!-- Status icon and name -->
      <v-col
        order="1"
        cols="11"
        sm="6"
        class="d-flex align-center py-1"
      >
        <FlightStatusIcon
          :status="flight.status"
          :forceDisplayBadge="flight.approval_can_be_created"
          :tooltip="computeTooltipMessage(flight)"
          withTooltip
          tooltipBottom
        />
        <div class="ml-3 subtitle-2 truncated">
          {{ flight.id }} - {{ flight.name }}
        </div>
      </v-col>
      <!-- Actions -->
      <v-col
        order="3"
        order-sm="2"
        cols="12"
        sm="5"
        class="py-1"
      >
        <FlightActions
          :flight="flight"
          tooltipBottom
          @weather-flight="$emit('weather-flight', flight)"
          @center-on-flight="$emit('center-on-flight', flight)"
          @edit-flight="$emit('edit-flight', flight)"
          @duplicate-flight="$emit('duplicate-flight', flight)"
          @close-flight="$emit('close-flight', flight)"
          @delete-flights="$emit('delete-flights', flight)"
          class="d-flex justify-center justify-sm-end"
        />
      </v-col>
      <!-- Close -->
      <v-col
        order="2"
        order-sm="3"
        cols="1"
        class="py-1"
      >
        <div
          class="d-flex justify-end"
          :class="{ 'pr-4': !isMobileBreakpoint }"
        >
          <v-icon
            @click="close()"
            color="white"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="selectedFlightLoading"
      type="list-item-three-line"
    />
    <template v-else>
      <!-- Flight details -->
      <FlightDetails :flight="flight" />
      <!-- Flight tools -->
      <FlightTools
        :flight="flight"
        @create-exclusion-zone="$emit('create-exclusion-zone', flight)"
      />
      <!-- Flight documents -->
      <FlightDocuments :flightId="flight.id" />
      <!-- Flight discussion -->
      <FlightDiscussion :flightId="flight.id" />
      <!-- Flight Livestreams -->
      <FlightLivestreams
        v-if="showLivestreamsFeature"
        :livestreams="livestreams"
      />
    </template>
    <!-- Loading constraints analysis -->
    <div
      v-if="!isFlightConstraintsAnalysisLoaded || loadingUSKAnalysis"
      key="constraints_loading"
      class="light flight-constraints__loader px-4 pt-4"
    >
      <v-progress-circular
        indeterminate
        color="white"
        :size="20"
        :width="2"
      />
      <span
        v-translate
        class="mx-4"
      >
        Constraints loading...
      </span>
    </div>
    <!-- Analysis processing -->
    <v-alert
      v-else-if="!flight.asp_was_analyzed"
      key="constraints_not_analyzed"
      type="info"
      color="grey lighten-3"
      class="mt-2 mb-0 py-2 grey--text text--darken-2 caption"
    >
      <span v-translate>Constraints are analysing, please reload later</span>
    </v-alert>
    <!-- Flight constraints -->
    <template v-else>
      <!-- Warning flight dates past -->
      <v-card
        v-if="isFlightDatePast"
        class="pa-2 mt-3"
        elevation="0"
        light
      >
        <v-alert
          class="ma-1 body-2"
          type="info"
          color="primary"
          text
        >
          <span class="info--text">
            {{ texts.analysisNotMaintained }}
          </span>
        </v-alert>
      </v-card>
      <!-- Warning different analysis from USK -->
      <v-card
        v-if="getUSKMissingConstraints.length"
        class="pa-2 mt-3"
        elevation="0"
        light
      >
        <v-alert
          v-for="(constraint, index) in getUSKMissingConstraints"
          :key="index"
          class="ma-1 body-2"
          type="error"
          icon="error"
          dense
        >
          {{ getMissingUSKConstraintText(constraint.authority_name) }}
        </v-alert>
      </v-card>
      <!-- Impacting constraints -->
      <FlightConstraintsList
        :flight="flight"
        category="impacting"
        :constraints="getAnalysisImpactingConstraints"
        class="mt-3"
      />
      <!-- Nearby constraints -->
      <FlightConstraintsList
        v-if="getAnalysisNearbyConstraints.length"
        :flight="flight"
        category="nearby"
        :constraints="getAnalysisNearbyConstraints"
        class="mt-3"
      />
    </template>
    <!-- Loading NOTAMs analysis -->
    <div
      v-if="!isFlightNotamsAnalysisLoaded"
      key="notams_loading"
      class="light flight-constraints__loader px-4 pt-4"
    >
      <v-progress-circular
        indeterminate
        color="white"
        :size="20"
        :width="2"
      />
      <span
        v-translate
        class="mx-4"
      >
        NOTAM loading...
      </span>
    </div>
    <!-- NOTAM Lists -->
    <template v-else>
      <!-- Important NOTAM and not classified -->
      <FlightNotamsList
        category="important"
        :notams="getAnalysisImportantAndUnclassifiedNotams"
        :flightStatus="getUIStatus"
        class="mt-3"
      />
      <!-- Interesting NOTAM -->
      <FlightNotamsList
        category="interesting"
        :notams="getAnalysisInterestingNotams"
        :flightStatus="getUIStatus"
        class="mt-3"
      />
      <!-- Irrelevant NOTAM -->
      <FlightNotamsList
        category="irrelevant"
        :notams="getAnalysisIrrelevantNotams"
        :flightStatus="getUIStatus"
        class="mt-3"
      />
    </template>
    <!-- Constraints verified by Clearance -->
    <div
      v-translate
      key="items_verified"
      @click="openItemsVerifiedDialog=true"
      class="caption text-decoration-underline cursor-pointer pt-6 px-4"
    >
      See all items verified by Clearance
    </div>
    <template v-if="!flight.is_archived">
      <!-- Take off button -->
      <v-row
        v-if="isFlightConstraintsAnalysisLoaded && !flight.en_route"
        no-gutters
        class="mt-5 d-flex flex-column"
      >
        <v-btn
          rounded
          block
          class="success--text font-weight-bold"
          min-height="48px"
          @click="openFlightEnRoute=true"
        >
          <v-icon
            color="success"
            class="mr-2"
          >
            mdi-airplane-takeoff
          </v-icon>
          <span v-translate>TAKE OFF</span>
        </v-btn>
        <span
          class="py-2 caption text-center"
          v-html="takeOffButtonText"
        />
      </v-row>
      <!-- Flight en route dialogs -->
      <FlightEnRoute
        v-if="flight.en_route || openFlightEnRoute"
        :flight="flight"
        :constraints="getAnalysisImpactingConstraints"
        :resume="flight.en_route"
        @close="() => openFlightEnRoute = false"
        @land-flight="({ closeFlight }) => landFlight({ closeFlight })"
      />
    </template>
    <!-- Dialog items vérified -->
    <v-dialog
      v-model="openItemsVerifiedDialog"
      scrollable
      max-width="940px"
    >
      <FlightItemsVerified />
    </v-dialog>
  </div>
</template>

<script>
import APIService from '@/services/api';

import {
  CONSTRAINTS_CATEGORY,
  CONSTRAINTS_PROCESS_THROUGH,
  EXTERNAL_OPERATORS,
  NOTAMS_CATEGORY,
  TAKE_OFF_ARTICLE_LINK,
} from '@/settings';

import { SET_FLIGHT_SELECTED_NS, SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS_NS } from '@/store/flights';

import FlightActions from '@/components/Flights/FlightActions.vue';
import FlightConstraintsList from '@/components/Flights/FlightConstraintsList.vue';
import FlightDetails from '@/components/Flights/FlightDetails.vue';
import FlightDiscussion from '@/components/Flights/FlightDiscussion.vue';
import FlightEnRoute from '@/components/Flights/FlightEnRoute.vue';
import FlightItemsVerified from '@/components/Flights/FlightItemsVerified.vue';
import FlightLivestreams from '@/components/Flights/FlightLivestreams.vue';
import FlightNotamsList from '@/components/Flights/FlightNotamsList.vue';
import FlightTools from '@/components/Flights/FlightTools.vue';
import FlightDocuments from '@/components/Flights/Documents/FlightDocuments.vue';
import FlightStatusIcon from '@/components/StatusIcon/FlightStatusIcon.vue';

export default {
  name: 'Flight',
  components: {
    FlightActions,
    FlightConstraintsList,
    FlightDetails,
    FlightDiscussion,
    FlightEnRoute,
    FlightItemsVerified,
    FlightLivestreams,
    FlightNotamsList,
    FlightTools,
    FlightDocuments,
    FlightStatusIcon,
  },
  props: { flight: Object },
  data() {
    return {
      openItemsVerifiedDialog: false,
      openFlightEnRoute: false,
      loadingUSKAnalysis: false,
    };
  },
  computed: {
    texts() {
      return {
        analysisNotMaintained: this.$gettext(`Analysis is not maintained for flights whose dates
          have passed. To see the updated analysis, you can duplicate your flight or change the
          dates of your flight.`),
      };
    },
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    display() {
      return this.$store.state.application.display;
    },
    isFlightDatePast() {
      const today = new Date();
      const endDate = new Date(this.flight.date_end);
      const endTime = this.flight.time_end.split(':').map((e) => parseInt(e, 10));
      endDate.setHours(endTime[0], endTime[1]);
      return endDate < today;
    },
    takeOffButtonText() {
      let text = '';
      if (this.flight.approvals.length >= 1) {
        text = this.$gettext(`Start the clock for your activity logs, and may inform partner
          airports if you filed an authorization request. You still have to comply to potential
          other requirements that may have been given to you by relevant airspace authorities.`);
      } else {
        text = this.$gettext('Start the clock for your activity logs.');
      }
      return text + this.$gettextInterpolate(
        this.$gettext(
          '<a href="%{link}" class="white--text ml-1" target="_blank">More information</a>.',
        ),
        { link: TAKE_OFF_ARTICLE_LINK },
      );
    },
    getConstraintsAnalysis() {
      return this.flight.constraintsAnalysis || [];
    },
    getAnalysisImpactingConstraints() {
      return this.getConstraintsAnalysis.filter(
        (constraint) => constraint.category === CONSTRAINTS_CATEGORY.IMPACTING,
      );
    },
    getAnalysisNearbyConstraints() {
      return this.getConstraintsAnalysis.filter(
        (constraint) => constraint.category === CONSTRAINTS_CATEGORY.NEARBY,
      );
    },
    getNotamsAnalysis() {
      return this.flight.notamsAnalysis || [];
    },
    getAnalysisImportantAndUnclassifiedNotams() {
      const importantNotams = this.getNotamsAnalysis.filter(
        (notam) => notam.category === NOTAMS_CATEGORY.IMPORTANT,
      );
      const notCheckedNotams = this.getNotamsAnalysis.filter(
        (notam) => notam.category === NOTAMS_CATEGORY.NOT_CHECKED,
      );
      return importantNotams.concat(notCheckedNotams);
    },
    getAnalysisInterestingNotams() {
      return this.getNotamsAnalysis.filter(
        (notam) => notam.category === NOTAMS_CATEGORY.INTERESTING,
      );
    },
    getAnalysisIrrelevantNotams() {
      return this.getNotamsAnalysis.filter(
        (notam) => notam.category === NOTAMS_CATEGORY.IRRELEVANT,
      );
    },
    getUIStatus() {
      const { is_archived: isArchived, status } = this.flight;
      return isArchived ? 'archived' : status;
    },
    getStatusClass() {
      return { [this.getUIStatus]: true };
    },
    selectedFlightLoading() {
      return this.$store.state.flights.isSelectedFlightDetailsLoading;
    },
    isFlightConstraintsAnalysisLoaded() {
      return this.$store.state.flights.isSelectedFlightConstraintsAnalysisLoaded;
    },
    isFlightNotamsAnalysisLoaded() {
      return this.$store.state.flights.isSelectedFlightNotamsAnalysisLoaded;
    },
    livestreams() {
      return this.$store.state.livestreams.livestreams.filter(
        (livestream) => livestream.flight === this.flight.id,
      );
    },
    showLivestreamsFeature() {
      return (
        !this.$store.getters['exploitants/hideLivestreamsFeature']
        && this.livestreams.length
      );
    },
    getUSKConstraints() {
      return this.getConstraintsAnalysis.filter(
        (c) => c.process_through === CONSTRAINTS_PROCESS_THROUGH.USK,
      );
    },
    getUSKAnalysis() {
      return this.flight.USKConstraintsAnalysis || [];
    },
    getUSKMissingConstraints() {
      const structureIds = this.getUSKConstraints.map((c) => c.structure_id);
      // Search if at least one structure from USK is included in Clearance analysis
      // Return all missing constraints
      return this.getUSKAnalysis.filter(
        (a) => !a.structure_ids.some((structureId) => structureIds.includes(structureId)),
      );
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.close();
      }
    },
    isFlightConstraintsAnalysisLoaded(newValue) {
      if (
        newValue
        && this.getUSKConstraints.length
      ) {
        this.getUSKFlightAnalysis();
      }
    },
  },
  methods: {
    close() {
      if (this.$route.query.idSelected !== undefined) {
        this.$router.replace({ query: null });
      }
      this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: null, reload: true });
    },
    computeTooltipMessage(flight) {
      if (flight.approval_can_be_created) {
        return this.$gettext(`You have an approval request that is not transmitted yet. You can
          send it by clicking on the Request Approval button in the constraints section of the
          flight.`);
      }
      return '';
    },
    landFlight({ closeFlight }) {
      this.openFlightEnRoute = false;
      if (closeFlight) {
        this.$emit('close-flight', this.flight);
      }
    },
    getUSKFlightAnalysis() {
      this.loadingUSKAnalysis = true;
      APIService.externalFlightAnalysis(this.flight.id, EXTERNAL_OPERATORS.USK)
        .then(({ data }) => {
          this.$store.commit(
            SET_FLIGHT_USK_CONSTRAINTS_ANALYSIS_NS, { flightId: this.flight.id, analysis: data },
          );
        })
        .finally(() => {
          this.loadingUSKAnalysis = false;
        });
    },
    getMissingUSKConstraintText(name) {
      return this.$gettextInterpolate(
        this.$gettext(`The U-SpaceKeeper platform has indicated that your mission is impacted by
          the area %{ name }, but it was not possible to associate it with one of the constraints
          of your your mission. For more information please contact support@clearance.aero.`),
        { name },
      );
    },

  },
};
</script>

<style
  lang="scss"
  scoped
>
.flight {
  background-color: $color-primary;
  &__title, &__status {
    color: $color-white;
    &.planned {
      background-color: $flight-planned-background-color;
    }
    &.processing {
      background-color: $flight-processing-background-color;
    }
    &.canceled {
      background-color: $flight-canceled-background-color;
    }
    &.error {
      background-color: $flight-error-background-color;
    }
    &.action_needed {
      background-color: $flight-action_needed-background-color;
    }
    &.archived {
      background-color: $flight-archived-background-color;
    }
    &.done {
      background-color: $flight-archived-background-color;
    }
    &.confirmed {
      background-color: $flight-confirmed-background-color;
    }
    &.clear {
      background-color: $flight-clear-background-color;
    }
    &.reserves {
      background-color: $flight-reserves-background-color;
    }
    &.refused {
      background-color: $flight-refused-background-color;
    }
  }
  &__container {
    min-height: 100%;
    padding-bottom: 32px;
  }
  &___title {
    font-weight: 400;
    font-size: 13px;
  }
}
.v-btn {
  min-width: 0;
}
.flight-constraints__loader {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

</style>
