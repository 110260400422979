<template>
  <LayoutSettings>
    <template
      slot="navigation-drawer"
      slot-scope="{switchDisplay}"
    >
      <v-list
        class="settings-categories"
        subheader
      >
        <v-subheader>
          <translate>Settings</translate>
        </v-subheader>
        <!-- Settings categories -->
        <v-list-item-group
          color="primary"
          v-model="settingsCategory"
        >
          <div
            v-for="(category, index) in settingsCategories"
            :key="index"
          >
            <!-- Settings simple category with no sub-categories -->
            <v-list-item
              v-if="!category.subCategories"
              :value="category.value"
              class="simple-category"
              @click="switchDisplay()"
            >
              <!-- Category title and icon  -->
              <v-list-item-icon>
                <v-icon>
                  {{ category.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ category.title }}
              </v-list-item-title>
            </v-list-item>
            <!-- Settings list category with sub-categories -->
            <v-list-group
              v-if="category.subCategories"
              class="list-category"
            >
              <!-- Category title and icon  -->
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>
                    {{ category.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ category.title }}
                </v-list-item-title>
                <v-progress-circular
                  v-if="loadingStructures"
                  indeterminate
                  color="primary"
                />
              </template>
              <!-- Sub-categories  -->
              <v-list-item
                v-for="subCategory in category.subCategories"
                :key="subCategory.title"
                :value="subCategory.value"
                class="sub-category"
                @click="switchDisplay()"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subCategory.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
    <template slot="contents">
      <!-- User -->
      <UserSettings
        v-if="settingsCategoryActivated === 'user'"
        :user-data="userData"
      />
      <!-- Airspace structures -->
      <StructureForm
        v-if="structure && settingsCategoryActivated === `${structure.id}-structures`"
        :key="`${structure}-structures` ? structure.id : ''"
        :structure="structure"
        :structureSettings="structure.structure_settings"
      />
      <!-- Email Preferences -->
      <v-container v-if="settingsCategoryActivated === 'email-preferences'">
        <div
          class="headline mb-4"
          key="email"
        >
          <translate>Email notifications preferences</translate>
        </div>
        <v-row
          no-gutters
          align="center"
        >
          <v-radio-group
            v-model="emailPreferences"
            :disabled="!emailPreferencesEditing"
            hide-details
            class="mt-0"
          >
            <v-radio
              value="all"
              :label="emailPreferencesLabels.all"
              color="primary"
            />
            <v-radio
              value="dronists_actions"
              :label="emailPreferencesLabels.dronistsActions"
              color="primary"
            />
            <v-radio
              value="none"
              :label="emailPreferencesLabels.any"
              color="primary"
            />
          </v-radio-group>
          <v-btn
            v-if="!emailPreferencesEditing"
            text
            icon
            class="ml-2"
            @click="emailPreferencesEditing=true"
          >
            <v-icon>icon-edit_line</v-icon>
          </v-btn>
        </v-row>
        <v-row
          v-if="emailPreferencesEditing"
          no-gutters
          class="mt-6"
        >
          <v-btn
            depressed
            @click="cancelEmailPreferences()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            depressed
            class="ml-4 primary--text"
            :loading="emailPreferencesLoading"
            @click="changeEmailPreferences()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-row>
      </v-container>
      <!-- Default basemap -->
      <v-container v-if="settingsCategoryActivated === 'basemap'">
        <div
          class="headline mb-4"
          key="basemap"
        >
          <translate>Default basemap</translate>
        </div>
        <v-row
          no-gutters
          align="center"
        >
          <v-radio-group
            v-model="basemapPreferences"
            :disabled="!basemapPreferencesEditing"
            row
            hide-details
            class="mt-0"
          >
            <v-radio
              v-for="(map, index) in userData.available_maps"
              :key="index"
              :label="map.name"
              color="primary"
              :value="map.identifier"
            />
          </v-radio-group>
          <v-btn
            v-if="!basemapPreferencesEditing"
            text
            icon
            @click="basemapPreferencesEditing=true"
          >
            <v-icon>icon-edit_line</v-icon>
          </v-btn>
        </v-row>
        <v-row
          v-if="basemapPreferencesEditing"
          no-gutters
          class="mt-6"
        >
          <v-btn
            depressed
            @click="cancelBasemapPreferences()"
          >
            <translate>Cancel</translate>
          </v-btn>
          <v-btn
            depressed
            class="ml-4 primary--text"
            :loading="basemapPreferencesLoading"
            @click="changeBasemapPreferences()"
          >
            <translate>Confirm</translate>
          </v-btn>
        </v-row>
      </v-container>
      <!-- Exploitants Statistics -->
      <ExploitantsStatistics
        v-if="structure && settingsCategoryActivated === `${structure.id}-exploitants`"
        :key="`${structure}-exploitants` ? structure.id : ''"
        :structure="{...structure}"
      />
      <!-- Approval Invoice List -->
      <ApprovalInvoiceListing
        v-if="structure && settingsCategoryActivated === `${structure.id}-invoices`"
        :key="`${structure}-invoices` ? structure.id : ''"
        :structure="{...structure}"
      />
      <!-- Personal data -->
      <DataSettings v-if="settingsCategoryActivated === 'data-settings'" />
    </template>
  </LayoutSettings>
</template>

<script>
import LayoutSettings from '@/layouts/LayoutSettings.vue';

import API from '@/services/api';

import {
  CHANGE_SUPERVISER_EMAIL_PREFERENCES_NS,
  CHANGE_SUPERVISER_BASEMAP_PREFERENCES_NS,
} from '@/store/authentication';
import { GET_AUTHORITY_STRUCTURES_NS, GET_AUTHORITY_LAYERS_NS } from '@/store/structures';

import ApprovalInvoiceListing from '@/components/Settings/Authorities/ApprovalInvoiceList.vue';
import ExploitantsStatistics from '@/components/Settings/Authorities/ExploitantsStatistics.vue';
import StructureForm from '@/components/Settings/Authorities/StructureForm.vue';
import UserSettings from '@/components/Settings/UserSettings.vue';
import DataSettings from '@/components/Settings/DataSettings.vue';

export default {
  name: 'SettingsAuthorities',
  components: {
    LayoutSettings,
    ApprovalInvoiceListing,
    ExploitantsStatistics,
    StructureForm,
    UserSettings,
    DataSettings,
  },
  data() {
    return {
      settingsCategoryActivated: null,
      emailPreferencesLoading: false,
      emailPreferencesEditing: false,
      emailPreferences: '',
      basemapPreferencesLoading: false,
      basemapPreferencesEditing: false,
      basemapPreferences: '',
    };
  },
  computed: {
    settingsCategory: {
      get() {
        return this.settingsCategoryActivated;
      },
      set(newValue) {
        if (this.settingsCategoriesValues.includes(newValue)) {
          this.settingsCategoryActivated = newValue;
        }
      },
    },
    settingsCategories() {
      const categories = [
        {
          title: this.$gettext('User'),
          icon: 'mdi-account',
          value: 'user',
        },
        {
          title: this.$gettext('Basemap'),
          icon: 'mdi-map',
          value: 'basemap',
        },
      ];
      if (this.isAuthorityAdminOrManager) {
        categories.push(
          {
            title: this.$gettext('Email notifications'),
            icon: 'mdi-email',
            value: 'email-preferences',
          },
        );
      }
      if (this.isAuthorityAdmin && !this.isUserPrefecture) {
        categories.push(
          {
            title: this.$gettext('Airspaces'),
            icon: 'mdi-shield-airplane',
            subCategories:
              this.structures.map((s) => ({ title: s.long_name, value: `${s.id}-structures` })),
          },
          {
            title: this.$gettext('Drones exploitants'),
            icon: 'mdi-briefcase-account',
            subCategories: this.structures.map(
              (s) => ({ title: s.long_name, value: `${s.id}-exploitants` }),
            ),
          },
        );
        if (this.hasStructuresRequiredApprovalPayment) {
          const structuresRequiredPayment = [];
          this.structures.forEach((s) => {
            if (s.has_connected_account) {
              structuresRequiredPayment.push({ title: s.long_name, value: `${s.id}-invoices` });
            }
          });
          categories.push({
            title: this.$gettext('Invoices'),
            icon: 'mdi-tag',
            subCategories: structuresRequiredPayment,
          });
        }
      }
      categories.push({
        title: this.$gettext('Personnal data'),
        icon: 'mdi-folder-account',
        value: 'data-settings',
      });

      return categories;
    },
    settingsCategoriesValues() {
      return this.settingsCategories.map(
        (c) => c.value || c.subCategories.map((s) => s.value),
      ).flat();
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    structures() {
      return this.$store.state.structures.items;
    },
    structure() {
      let structureId;
      if (this.settingsCategoryActivated) {
        structureId = Number(this.settingsCategoryActivated.split('-')[0]);
      }
      return this.structures.find((s) => (s.id === structureId));
    },
    loadingStructures() {
      return this.$store.state.structures.loadingStructures && !this.structures.length;
    },
    isAuthorityAdmin() {
      return this.$store.getters['authentication/isAuthorityAdmin'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
    isUserPrefecture() {
      return this.$store.getters['authentication/isUserPrefecture'];
    },
    emailPreferencesLabels() {
      return {
        all: this.$gettext('Receive all email notifications.'),
        dronistsActions: this.$gettext('Only receive email notifications of dronists actions.'),
        any: this.$gettext('Receive any email notifications.'),
      };
    },
    superviser() {
      return this.userData.superviser;
    },
    hasStructuresRequiredApprovalPayment() {
      return this.superviser.has_structures_required_approval_payment;
    },
  },
  created() {
    this.getAuthorityStructures();
    this.getAuthorityLayers();
    this.emailPreferences = this.superviser.email_notification_preferences;
    this.basemapPreferences = this.superviser.preferred_basemap;
  },
  mounted() {
    const { xs } = this.$vuetify.breakpoint;
    // Automatically select the first category on non mobile screens
    if (!xs) [this.settingsCategory] = this.settingsCategoriesValues;
  },
  methods: {
    changeEmailPreferences() {
      const payload = { superviser_email_preferences: this.emailPreferences };
      this.emailPreferencesLoading = true;
      API.modifyUser(this.userData.id, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_SUPERVISER_EMAIL_PREFERENCES_NS, this.emailPreferences)
            .then(() => {
              this.showMessage(this.$gettext('Emails notification updated'), 'success');
              this.emailPreferencesLoading = false;
              this.emailPreferencesEditing = false;
            });
        })
        .finally(() => {
          this.emailPreferencesLoading = false;
          this.emailPreferencesEditing = false;
        });
    },
    cancelEmailPreferences() {
      this.emailPreferencesEditing = false;
      this.emailPreferences = this.superviser.email_notification_preferences;
    },
    changeBasemapPreferences() {
      const payload = { superviser_basemap_preferences: this.basemapPreferences };
      this.basemapPreferencesLoading = true;
      API.modifyUser(this.userData.id, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_SUPERVISER_BASEMAP_PREFERENCES_NS, this.basemapPreferences)
            .then(() => {
              this.showMessage(this.$gettext('Basemap updated'), 'success');
              this.basemapPreferencesLoading = false;
              this.basemapPreferencesEditing = false;
            });
        })
        .finally(() => {
          this.basemapPreferencesLoading = false;
          this.basemapPreferencesEditing = false;
        });
    },
    cancelBasemapPreferences() {
      this.basemapPreferencesEditing = false;
      this.basemapPreferences = this.superviser.preferred_basemap;
    },
    async getAuthorityStructures() {
      await this.$store.dispatch(GET_AUTHORITY_STRUCTURES_NS);
    },
    async getAuthorityLayers() {
      await this.$store.dispatch(GET_AUTHORITY_LAYERS_NS);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.settings-categories {
  .v-list-item {
    padding-left: 0.6em;
  }
  .v-list-item__icon {
    margin-right: 1.2em;
  }
  .list-category {
    /deep/ .v-list-group__header {
      padding-left: 0.6em;
      color: rgba(0, 0, 0, .54);
      .v-list-item__title {
        color: rgba(0, 0, 0, .87) !important;
      }
    }
    .sub-category {
      min-height: 40px;
      padding-left: 3.6em;
      .v-list-item__content {
        padding: 0px;
      }
      .v-list-item__title {
        font-size: 1em;
      }
    }
  }
}

.settings__airspace-parameter {
  justify-content: space-between;
  align-items: center;
}

.settings__edit-button-container {
  justify-content: flex-end;
  display: flex;
}

</style>
