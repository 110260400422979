import Vue from 'vue';

import APIService from '@/services/api';

const namespace = 'structures';

const FETCH_STRUCTURES = 'FETCH_STRUCTURES';
const GET_STRUCTURES_GEOMETRIES = 'GET_STRUCTURES_GEOMETRIES';
const GET_AUTHORITY_STRUCTURES = 'GET_AUTHORITY_STRUCTURES';
const GET_AUTHORITY_LAYERS = 'GET_AUTHORITY_LAYERS';
const SET_STRUCTURES = 'SET_STRUCTURES';
const SET_EXTERNAL_STRUCTURES_GEOMETRIES = 'SET_EXTERNAL_STRUCTURES_GEOMETRIES';
const SET_LAYERS = 'SET_LAYERS';
const SET_LAYERS_LOADED = 'SET_LAYERS_LOADED';
const FETCH_EXTERNAL_STRUCTURES_GEOMETRIES = 'FETCH_EXTERNAL_STRUCTURES_GEOMETRIES';
const GET_STRUCTURES_AIP_GEOMETRIES = 'GET_STRUCTURES_AIP_GEOMETRIES';
const ZOOM_ON_STRUCTURES = 'ZOOM_ON_STRUCTURES';

export const GET_STRUCTURES_GEOMETRIES_NS = `${namespace}/${GET_STRUCTURES_GEOMETRIES}`;
export const GET_AUTHORITY_STRUCTURES_NS = `${namespace}/${GET_AUTHORITY_STRUCTURES}`;
export const GET_AUTHORITY_LAYERS_NS = `${namespace}/${GET_AUTHORITY_LAYERS}`;
export const GET_STRUCTURES_AIP_GEOMETRIES_NS = `${namespace}/${GET_STRUCTURES_AIP_GEOMETRIES}`;
export const ZOOM_ON_STRUCTURES_NS = `${namespace}/${ZOOM_ON_STRUCTURES}`;

export default {
  namespaced: true,
  state: {
    items: [],
    loadingStructures: false,
    loadingStructuresError: {},
    externalStructuresGeometries: {},
    loadingExternalStructuresGeometries: false,
    totalItems: 0,
    layers: [],
    loadingLayersError: {},
    layersLoaded: false,
    structuresAIPGeometries: { type: 'FeatureCollection', features: [] },
  },
  getters: {
    structureDetails: (state, getters, rootState, rootGetters) => {
      let structureDetails = {};
      if (Array.isArray(state.items)) {
        if (state.items.length === 1) {
          structureDetails = state.items.at(0);
        } else {
          let structureId;
          if (rootGetters['approvals/approvalSelected']) {
            structureId = rootGetters['approvals/approvalSelected'].structure_id;
          } else if (rootGetters['activations/activationSelected']) {
            structureId = rootGetters['activations/activationSelected'].approval.structure_id;
          }
          if (structureId) {
            structureDetails = state.items.find((s) => s.id === structureId) || {};
          }
        }
      }
      return structureDetails;
    },
    structureSettings: (state, getters) => getters.structureDetails.structure_settings || {},
    tacticalContactProtocols: (state) => {
      if (Array.isArray(state.items)) {
        return state.items.filter(
          (item) => (item.structure_settings?.tactical_contact_protocol?.length),
        ).map((item) => item.structure_settings.tactical_contact_protocol);
      }
      return [];
    },
    activationRequired: (state) => {
      if (Array.isArray(state.items)) {
        return state.items.some(
          (structure) => structure.structure_settings?.activation_required,
        );
      }
      return false;
    },
    takeOffAuthorizationActivated: (state) => {
      if (Array.isArray(state.items)) {
        return state.items.some(
          (structure) => structure.structure_settings?.take_off_authorization_activated,
        );
      }
      return false;
    },
    unitsDisplayed: (state) => {
      if (Array.isArray(state.items)) {
        const unitsDisplayed = state.items.filter(
          (item) => item.structure_settings?.unit_displayed.length,
        ).map((item) => item.structure_settings.unit_displayed);
        const uniqueUnitsDisplayed = [...new Set(unitsDisplayed)];
        return uniqueUnitsDisplayed;
      }
      return [];
    },
    structuresBbox: (state) => {
      if (!Array.isArray(state.items)) return [];

      return [
        [
          Math.min(...state.items.map((s) => s.long_min)),
          Math.min(...state.items.map((s) => s.lat_min)),
        ],
        [
          Math.max(...state.items.map((s) => s.long_max)),
          Math.max(...state.items.map((s) => s.lat_max)),
        ],
      ];
    },
  },
  mutations: {
    [FETCH_STRUCTURES](state) {
      Vue.set(state, 'loadingStructures', true);
    },
    [SET_STRUCTURES](state, { items = [], totalItems = 0, loadingStructuresError }) {
      Vue.set(state, 'loadingStructures', false);
      Vue.set(state, 'items', items);
      Vue.set(state, 'totalItems', totalItems);
      Vue.set(state, 'loadingStructuresError', loadingStructuresError);
    },
    [FETCH_EXTERNAL_STRUCTURES_GEOMETRIES](state, value) {
      Vue.set(state, 'loadingExternalStructuresGeometries', value);
    },
    [SET_EXTERNAL_STRUCTURES_GEOMETRIES](state, externalStructuresGeometries) {
      Vue.set(state, 'externalStructuresGeometries', externalStructuresGeometries);
      Vue.set(state, 'loadingExternalStructuresGeometries', false);
    },
    [SET_LAYERS](state, { items = [], loadingLayersError }) {
      Vue.set(state, 'layers', items);
      Vue.set(state, 'loadingLayersError', loadingLayersError);
      Vue.set(state, 'layersLoaded', true);
    },
    [SET_LAYERS_LOADED](state, layersLoaded) {
      Vue.set(state, 'layersLoaded', layersLoaded);
    },
    [GET_STRUCTURES_AIP_GEOMETRIES](state, featuresCollection) {
      Vue.set(state, 'structuresAIPGeometries', featuresCollection);
    },
  },
  actions: {
    [GET_STRUCTURES_GEOMETRIES]({ commit, rootGetters }) {
      commit(FETCH_STRUCTURES);
      if (rootGetters['authentication/userNotSubscribed']) {
        commit(FETCH_EXTERNAL_STRUCTURES_GEOMETRIES, true);
        APIService.getAllStructuresGeometries()
          .then(({ data }) => {
            const registeredStructures = data.features.find((f) => f.properties.type === 'registered');
            commit(SET_STRUCTURES, { items: registeredStructures });
            const externalStructures = data.features.find((f) => f.properties.type === 'external');
            commit(SET_EXTERNAL_STRUCTURES_GEOMETRIES, externalStructures);
          })
          .catch((error) => {
            commit(SET_STRUCTURES, { loadingStructuresError: error });
            commit(FETCH_EXTERNAL_STRUCTURES_GEOMETRIES, false);
          });
      } else {
        APIService.getStructuresGeometries()
          .then(({ data }) => {
            commit(SET_STRUCTURES, { items: data });
          })
          .catch((error) => {
            commit(SET_STRUCTURES, { loadingStructuresError: error });
          });
      }
    },
    async [GET_AUTHORITY_STRUCTURES]({ commit }) {
      commit(FETCH_STRUCTURES);
      await APIService.getAuthorityStructures()
        .then(({ data }) => {
          commit(SET_STRUCTURES, {
            items: data,
            totalItems: data.length,
          });
        })
        .catch((error) => {
          commit(SET_STRUCTURES, { loadingStructuresError: error });
        });
    },
    async [GET_AUTHORITY_LAYERS]({ commit }) {
      commit(SET_LAYERS_LOADED, false);
      await APIService.getAuthorityLayers()
        .then(({ data }) => {
          commit(SET_LAYERS, { items: data });
        })
        .catch((error) => {
          commit(SET_LAYERS, { loadingLayersError: error });
        });
    },
    [GET_STRUCTURES_AIP_GEOMETRIES]({ commit }) {
      APIService.getAuthorityAIPGeometries()
        .then(({ data }) => {
          commit(GET_STRUCTURES_AIP_GEOMETRIES, data);
        });
    },
    [ZOOM_ON_STRUCTURES]({ dispatch, getters }) {
      const { structuresBbox } = getters;
      if (structuresBbox.length) {
        dispatch('map/SET_BOUNDING_BOX', { boundingBox: structuresBbox }, { root: true });
      }
    },
  },
};
