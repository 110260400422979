<template>
  <div class="full-height d-flex flex-column">
    <v-container class="px-1 px-md-3 flex-grow-0">
      <!-- Default flight values -->
      <div class="headline pb-4">
        <translate>Default values</translate>
      </div>
      <div class="pa-2">
        <p class="body-2 text--secondary">
          {{ defaultValuesHint }}
        </p>
      </div>
      <div class="px-2">
        <!-- Default flying height -->
        <template v-if="defaultFlyingHeightEditing">
          <v-row
            no-gutters
            align="center"
            class="pb-1 pt-3 pt-md-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Default Flying Height
              </div>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-form
                lazy-validation
                v-model="defaultFlyingHeightValid"
              >
                <v-text-field
                  v-model="defaultFlyingHeight"
                  type="number"
                  :rules="rules.defaultFlyingHeight"
                  :hint="$gettext('Default flying height on flight creation')"
                  class="p-0"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            class="pb-5"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="defaultFlyingHeightEditing=false"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :disabled="!defaultFlyingHeightValid"
              :loading="defaultFlyingHeightLoading"
              @click="changeFlyingHeight()"
            >
              <translate>Validate</translate>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            align="end"
            class="pt-3 pb-5"
          >
            <v-col
              cols="12"
              md="4"
              class="pb-2"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Default flying height
              </div>
            </v-col>
            <v-col
              cols="11"
              md="7"
              class="pb-2"
            >
              {{ defaultFlyingHeight }}
            </v-col>
            <v-col
              cols="1"
              class="pb-2 d-flex justify-end"
            >
              <v-btn
                text
                icon
                class="edit-icon"
                @click="defaultFlyingHeightEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- Default flying time -->
        <template v-if="defaultFlyingTimeEditing">
          <v-row
            no-gutters
            align="center"
            class="pb-1 pt-3 pt-md-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Default Flying Time
              </div>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <vue-timepicker
                :minute-interval="15"
                v-model="defaultFlyingTime"
                hide-clear-button
                format="HH:mm"
                class="primary--time-picker"
              />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="end"
            class="pb-5"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="defaultFlyingTimeEditing=false"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              :disabled="!defaultFlyingTimeValid"
              :loading="defaultFlyingTimeLoading"
              @click="changeFlyingTime()"
            >
              <translate>Validate</translate>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            align="end"
            class="pt-3 pb-5"
          >
            <v-col
              cols="12"
              md="4"
              class="pb-2"
            >
              <div
                class="body-2 grey--text text--darken-2"
                v-translate
              >
                Default flying time
              </div>
            </v-col>
            <v-col
              cols="11"
              md="7"
              class="pb-2"
            >
              {{ defaultFlyingTime["HH"] }} : {{ defaultFlyingTime["mm"] }}
            </v-col>
            <v-col
              cols="1"
              class="pb-2 d-flex justify-end"
            >
              <v-btn
                text
                icon
                class="edit-icon"
                @click="defaultFlyingTimeEditing=true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- Internal note tooltip -->
        <v-row
          v-if="isUserEnterprise && isUserExploitantAdmin"
          no-gutters
          align="center"
          class="pt-3 pb-5"
        >
          <v-col
            cols="12"
            md="4"
          >
            <span
              class="body-2 grey--text text--darken-2"
              v-translate
            >
              Internal note explain
            </span>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-textarea
              v-if="internalNoteTooltipEditing"
              v-model="internalNoteTooltipNewValue"
              rows="3"
            />
            <div
              v-else
              class="d-flex align-center justify-space-between"
            >
              <span>
                {{ exploitationDetails.flight_internal_note_tooltip }}
              </span>
              <v-btn
                text
                icon
                class="edit-icon"
                @click="internalNoteTooltipEditing = true"
              >
                <v-icon>icon-edit_line</v-icon>
              </v-btn>
            </div>
          </v-col>
          <!-- Internal note tooltip editing buttons -->
          <v-col
            v-if="internalNoteTooltipEditing"
            offset="8"
            cols="4"
            class="d-flex align-center justify-end"
          >
            <v-btn
              class="grey lighten-4 info--text mx-2 my-1"
              depressed
              @click="internalNoteTooltipEditing = false"
            >
              <translate>Cancel</translate>
            </v-btn>
            <v-btn
              :loading="internalNoteTooltipLoading"
              class="grey lighten-4 primary--text mx-2 my-1"
              depressed
              @click="changeInternalNoteTooltip()"
            >
              <translate>Validate</translate>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- Flight Tags -->
      <template v-if="isUserEnterprise">
        <v-divider />
        <FlightTagsSettings />
      </template>
    </v-container>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

import APIService from '@/services/api';

import {
  CHANGE_DEFAULT_FLYING_HEIGHT_NS,
  CHANGE_DEFAULT_FLYING_TIME_NS,
} from '@/store/authentication';
import { CHANGE_INTERNAL_NOTE_TOOLTIP_NS } from '@/store/exploitants';

import FlightTagsSettings from '@/components/Settings/Dronists/FlightTagsSettings.vue';

export default {
  name: 'FlightSettings',
  components: { VueTimepicker, FlightTagsSettings },
  data() {
    return {
      defaultFlyingHeightEditing: false,
      defaultFlyingHeightLoading: false,
      defaultFlyingHeightNewValue: null,
      defaultFlyingHeightValid: true,
      defaultFlyingTimeEditing: false,
      defaultFlyingTimeLoading: false,
      defaultFlyingTimeNewValue: null,
      // Internal note tootlip
      internalNoteTooltipEditing: false,
      internalNoteTooltipLoading: false,
      internalNoteTooltipNewValue: '',
      rules: {
        defaultFlyingHeight: [
          (v) => !!v || this.$gettext('A default flying height is required'),
          (v) => v > 0 || this.$gettext('A positive integer is required'),
        ],
      },
    };
  },
  computed: {
    isUserEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    isUserExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    contactId() {
      return this.userData.contact_id;
    },
    exploitationDetails() {
      return this.$store.state.exploitants.exploitationDetails;
    },
    defaultValuesHint() {
      return this.$gettext(
        `These default values will be used to prefill the form for creating a new flight.
        They are specific to your personnal account.`,
      );
    },
    defaultFlyingHeight: {
      get() {
        return this.userData.default_flying_height;
      },
      set(newValue) {
        this.defaultFlyingHeightNewValue = Number(newValue);
      },
    },
    defaultFlyingTime: {
      get() {
        const [HH, mm] = this.userData.default_flying_time.split(':');
        return { HH, mm };
      },
      set(newValue) {
        const { HH, mm } = newValue;
        this.defaultFlyingTimeNewValue = [HH, mm, '00'].join(':');
      },
    },
    defaultFlyingTimeValid() {
      return (
        this.defaultFlyingTimeNewValue
        && this.defaultFlyingTimeNewValue !== '00:00:00'
        && this.defaultFlyingTimeNewValue.length === 8
      );
    },
  },
  mounted() {
    this.internalNoteTooltipNewValue = this.exploitationDetails.flight_internal_note_tooltip;
  },
  methods: {
    async changeFlyingHeight() {
      this.defaultFlyingHeightLoading = true;
      const payload = { default_flying_height: this.defaultFlyingHeightNewValue };
      await APIService.updateContactSettings(this.contactId, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_DEFAULT_FLYING_HEIGHT_NS, this.defaultFlyingHeightNewValue)
            .then(() => {
              this.showMessage(this.$gettext('The flying height has been updated'), 'success');
            });
        })
        .finally(() => {
          this.defaultFlyingHeightLoading = false;
          this.defaultFlyingHeightEditing = false;
        });
    },
    async changeFlyingTime() {
      this.defaultFlyingTimeLoading = true;
      const payload = { default_flying_time: this.defaultFlyingTimeNewValue };
      await APIService.updateContactSettings(this.contactId, payload)
        .then(() => {
          this.$store.dispatch(CHANGE_DEFAULT_FLYING_TIME_NS, this.defaultFlyingTimeNewValue)
            .then(() => {
              this.showMessage(this.$gettext('Default flying time updated'), 'success');
            });
        })
        .finally(() => {
          this.defaultFlyingTimeEditing = false;
          this.defaultFlyingTimeLoading = false;
        });
    },
    changeInternalNoteTooltip() {
      this.internalNoteTooltipLoading = true;
      const payload = { flight_internal_note_tooltip: this.internalNoteTooltipNewValue };
      APIService.updateExploitation(this.userData.exploitation_id, payload)
        .then(() => {
          this.$store.commit(CHANGE_INTERNAL_NOTE_TOOLTIP_NS, this.internalNoteTooltipNewValue);
          this.showMessage(this.$gettext('Internal note explain updated'), 'success');
        })
        .finally(() => {
          this.internalNoteTooltipEditing = false;
          this.internalNoteTooltipLoading = false;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.full-height {
  height: 100%;
}

.edit-icon {
  max-width: 24px;
  max-height: 24px;
}

</style>
