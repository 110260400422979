import Vue from 'vue';
import * as turf from '@turf/turf';

import APIService from '@/services/api';

import { getPageFromNextPreviousURL } from '@/store/application';
import {
  SELECTABLE_FEATURE_KEYS,
  SET_MAP_FEATURE_HOVERED_NS,
  SET_MAP_FEATURE_SELECTED_NS,
} from '@/store/map';

const NOT_FOUND_ERROR = 'NOT FOUND';

const namespace = 'traces';

const RESET_FILTERS = 'RESET_FILTERS';
const SET_FILTERS = 'SET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const GET_TRACES_GEOMETRY = 'GET_TRACES_GEOMETRY';
const SET_TRACES_COLLECTION_LOADING = 'SET_TRACES_COLLECTION_LOADING';
const SET_TRACES_COLLECTION = 'SET_TRACES_COLLECTION';
const SET_TRACES_COLLECTION_LOADED = 'SET_TRACES_COLLECTION_LOADED';
const GET_TRACES = 'GET_TRACES';
const SET_TABLE_ITEMS_LOADING = 'SET_TABLE_ITEMS_LOADING';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const SET_TRACE_HOVER = 'SET_TRACE_HOVER';
const SET_TRACE_SELECTED = 'SET_TRACE_SELECTED';
const GET_TRACE_DETAILS = 'GET_TRACE_DETAILS';
const SET_TRACE_DETAILS_LOADING = 'SET_TRACE_DETAILS_LOADING';
const SET_TRACE_DETAILS = 'SET_TRACE_DETAILS';
const UPDATE_TRACES = 'UPDATE_TRACES';
const COMPLETE_TRACE = 'COMPLETE_TRACE';
const CREATE_TRACE = 'CREATE_TRACE';

export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;
export const GET_TRACES_NS = `${namespace}/${GET_TRACES}`;
export const SET_TRACE_HOVER_NS = `${namespace}/${SET_TRACE_HOVER}`;
export const SET_TRACE_SELECTED_NS = `${namespace}/${SET_TRACE_SELECTED}`;

const defaultFilters = {
  dateFrom: '',
  dateTo: '',
  q: null,
};

function initialDateFrom() {
  const tenDaysBeforeInMinutes = 10 * 24 * 60; // days * hours * minutes
  const initialDatetimeFrom = new Date();
  initialDatetimeFrom.setMinutes(
    initialDatetimeFrom.getMinutes()
    + initialDatetimeFrom.getTimezoneOffset()
    - tenDaysBeforeInMinutes,
  );
  const isoInitialDatetimeFrom = initialDatetimeFrom.toISOString();
  const isoInitialDateFrom = isoInitialDatetimeFrom.slice(0, 10);

  return isoInitialDateFrom;
}

function updateTrace(state, { traceId, properties }) {
  const index = state.tracesCollection.findIndex((f) => f.id === traceId);
  const feature = { ...state.tracesCollection[index] };
  const updatedProperties = { ...feature, ...properties };
  Vue.set(state.tracesCollection, index, updatedProperties);
}

function initialState() {
  return {
    filters: { ...defaultFilters, dateFrom: initialDateFrom() },
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['-id'],
      sortDesc: [false],
    },
    tracesCollection: [],
    tracesCollectionLoading: false,
    tracesCollectionLoaded: false,
    itemsLength: 0,
    tableIds: [],
    tableItemsLoading: false,
    traceDetailsLoading: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.tracesCollection.forEach((trace) => {
        const index = state.tableIds.indexOf(trace.id);
        if (index !== -1) tableItems[index] = trace;
      });
      return tableItems;
    },
    mapTracesCenters: (state) => {
      const features = [];
      state.tracesCollection.forEach((trace) => {
        const { point_first_detection: pointFirstDetection, ...properties } = trace;
        features.push({
          type: 'Feature',
          id: properties.id,
          geometry: pointFirstDetection,
          properties,
        });
      });
      return { type: 'FeatureCollection', features };
    },
    mapTracesLines: (state) => {
      const features = [];
      state.tracesCollection.forEach((trace) => {
        const { geometry, ...properties } = trace;
        features.push({
          type: 'Feature',
          id: properties.id,
          geometry,
          properties,
        });
      });
      return { type: 'FeatureCollection', features };
    },
    allMapTracesDashLine: (state) => {
      const features = [];
      state.tracesCollection.forEach((trace) => {
        const { geometry, ...properties } = trace;
        if (!geometry) return;

        const { geometries: geometryCollection } = geometry;
        const latePoints = geometryCollection.filter((g) => g.type === 'Point');
        if (!latePoints.length) return;

        latePoints.forEach((latePoint) => {
          const index = geometryCollection.findIndex((g) => g === latePoint);
          if (index === 0) return;

          const previousGeometry = geometryCollection[index - 1];
          let previousPointCoordinates;
          if (previousGeometry.type === 'Point') {
            previousPointCoordinates = previousGeometry.coordinates;
          } else {
            previousPointCoordinates = previousGeometry.coordinates[
              previousGeometry.coordinates.length - 1
            ];
          }
          const dashLineGeometry = turf.lineString(
            [previousPointCoordinates, latePoint.coordinates],
          );
          features.push({
            type: 'Feature',
            id: properties.id,
            geometry: dashLineGeometry.geometry,
            properties,
          });
        });
      });

      return features;
    },
    mapTracesDashLine: (state, getters) => {
      const features = getters.allMapTracesDashLine;
      const featuresToDisplay = features.filter(
        (f) => ![getters.traceHover?.id, getters.traceSelected?.id].includes(f.id),
      );

      return { type: 'FeatureCollection', features: featuresToDisplay };
    },
    selectedMapTracesDashLine: (state, getters) => {
      const features = getters.allMapTracesDashLine;
      const featuresToDisplay = features.filter(
        (f) => [getters.traceHover?.id, getters.traceSelected?.id].includes(f.id),
      );

      return { type: 'FeatureCollection', features: featuresToDisplay };
    },
    activeFilters: (state) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (filters[key] !== defaultFilters[key]) {
          activeFilters += 1;
        }
      });
      return activeFilters;
    },
    traceHover: (state, getters, rootState) => {
      const traceId = rootState.map.featureIdHovered.trace;
      return state.tracesCollection.find((trace) => trace.id === traceId);
    },
    traceSelected: (state, getters, rootState) => {
      const traceId = rootState.map.featureIdSelected.trace;
      return state.tracesCollection.find((trace) => trace.id === traceId);
    },
  },
  mutations: {
    [RESET_FILTERS](state) {
      Vue.set(state, 'filters', { ...defaultFilters });
    },
    [SET_FILTERS](state, filters) {
      Vue.set(state, 'filters', { ...state.filters, ...filters });
    },
    [SET_PAGINATION](state, payload) {
      Vue.set(state, 'pagination', payload.pagination);
    },
    [SET_TRACES_COLLECTION_LOADING](state, loading) {
      Vue.set(state, 'tracesCollectionLoading', loading);
    },
    [SET_TRACES_COLLECTION](state, tracesCollection = []) {
      Vue.set(state, 'tracesCollection', tracesCollection);
    },
    [SET_TRACES_COLLECTION_LOADED](state) {
      Vue.set(state, 'tracesCollectionLoaded', true);
    },
    [SET_TABLE_ITEMS_LOADING](state, loading) {
      Vue.set(state, 'tableItemsLoading', loading);
    },
    [SET_TABLE_ITEMS](state, items = []) {
      items.forEach((item) => {
        updateTrace(state, { traceId: item.id, properties: { ...item } });
      });
      Vue.set(state, 'tableIds', items.map((i) => i.id));
      Vue.set(state, 'tableItemsLoading', false);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      if (itemsLength !== undefined) {
        Vue.set(state, 'itemsLength', itemsLength);
      }
      if (page !== undefined) {
        Vue.set(state.pagination, 'page', page);
      }
    },
    [SET_TRACE_DETAILS_LOADING](state) {
      Vue.set(state, 'traceDetailsLoading', true);
    },
    [SET_TRACE_DETAILS](state, { traceId, trace }) {
      updateTrace(state, { traceId, properties: trace });
      Vue.set(state, 'traceDetailsLoading', false);
    },
    [COMPLETE_TRACE](state, { traceIndex, position }) {
      const trace = {
        ...state.tracesCollection[traceIndex],
        is_active: true,
        last_detection: position.timestamp,
      };
      if (position.altitude) {
        trace.altitude_max = Math.max(trace.altitude_max, parseFloat(position.altitude));
      }

      const { geometries } = trace.geometry;
      if (geometries.length && !position.late) {
        const previousGeometry = geometries[geometries.length - 1];
        let previousPointCoordinates;
        if (previousGeometry.type === 'Point') {
          previousPointCoordinates = previousGeometry.coordinates;
        } else {
          previousPointCoordinates = previousGeometry.coordinates[
            previousGeometry.coordinates.length - 1
          ];
        }
        geometries.push(
          {
            type: 'LineString',
            coordinates: [
              previousPointCoordinates,
              [position.longitude, position.latitude],
            ],
          },
        );
      } else {
        geometries.push({
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        });
      }
      trace.geometry.geometries = geometries;
      updateTrace(state, { traceId: trace.id, properties: trace });
    },
    [CREATE_TRACE](state, { position }) {
      const trace = {
        id: position.trace_id,
        is_active: true,
        device_id: position.tracker_device_id,
        altitude_max: parseFloat(position.altitude),
        first_detection: position.timestamp,
        last_detection: position.timestamp,
        longitude_first_detection: position.longitude,
        latitude_first_detection: position.latitude,
        point_first_detection: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
        geometry: {
          type: 'GeometryCollection',
          geometries: [],
        },
        approvals: position.approvals.map((approval) => ({
          id: approval.approval_id,
          identifier: approval.approval_identifier,
          status: approval.approval_status,
        })),
      };
      state.tableIds.unshift(trace.id);
      state.tracesCollection.unshift(trace);
    },
  },
  actions: {
    [SET_PAGINATION]({ commit, dispatch }, pagination) {
      dispatch(SET_TRACE_SELECTED, null);
      commit(SET_PAGINATION, { pagination });
      dispatch(GET_TRACES, { reloadGeometries: false });
    },
    async [GET_TRACES_GEOMETRY]({ commit, state }) {
      commit(SET_TRACES_COLLECTION_LOADING, true);
      const { sortBy, sortDesc } = state.pagination;
      const { filters } = state;

      const traceParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        ...filters,
      };
      await APIService.getTrackerTracesGeoCollection({ ...traceParams })
        .then(({ data }) => {
          commit(SET_TRACES_COLLECTION, data.results);
          commit(SET_TRACES_COLLECTION_LOADED);
        })
        .finally(() => {
          commit(SET_TRACES_COLLECTION_LOADING, false);
        });
    },
    async [GET_TRACES]({ commit, dispatch, state }, { withId, reloadGeometries = true }) {
      commit(SET_TABLE_ITEMS_LOADING, true);
      if (reloadGeometries || !state.tracesCollectionLoaded) {
        await dispatch(GET_TRACES_GEOMETRY);
      }
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const { filters } = state;

      const traceParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        ...filters,
      };
      if (withId) {
        traceParams.withId = withId;
      } else {
        traceParams.offset = (page - 1) * itemsPerPage;
      }
      await APIService.getTrackerTraces({ ...traceParams })
        .then(({ data }) => {
          const { count: itemsLength, results: items } = data;
          let newPage;
          if (withId) {
            if (!items.find((trace) => trace.id === withId)) {
              throw new Error(NOT_FOUND_ERROR);
            }
            // Find new page where the trace with the given id is
            newPage = getPageFromNextPreviousURL(
              data.next,
              data.previous,
              itemsLength,
              itemsPerPage,
            );
          }
          commit(SET_TABLE_ITEMS, items);
          commit(UPDATE_PAGINATION, { itemsLength, page: newPage });
        })
        .finally(() => {
          commit(SET_TABLE_ITEMS_LOADING, false);
        });
    },
    [SET_TRACE_HOVER]({ dispatch }, id) {
      dispatch(
        SET_MAP_FEATURE_HOVERED_NS,
        { featureId: id, key: SELECTABLE_FEATURE_KEYS.trace },
        { root: true },
      );
    },
    [SET_TRACE_SELECTED]({ dispatch }, id) {
      dispatch(
        SET_MAP_FEATURE_SELECTED_NS,
        { featureId: id, key: SELECTABLE_FEATURE_KEYS.trace },
        { root: true },
      );
      if (id) {
        dispatch(GET_TRACE_DETAILS, id);
      }
    },
    async [GET_TRACE_DETAILS]({ commit, dispatch, state }, traceId) {
      commit(SET_TRACE_DETAILS_LOADING);
      if (!state.tableIds.some((id) => id === traceId)) {
        await dispatch(GET_TRACES, { withId: traceId, reloadGeometries: false });
      }
      await APIService.getTrackerTraceDetails(traceId)
        .then(({ data }) => {
          commit(SET_TRACE_DETAILS, { traceId, trace: data });
        });
    },
    [UPDATE_TRACES]({ commit, state }, positions) {
      positions.forEach((position) => {
        const traceIndex = state.tracesCollection.findIndex(
          (trace) => trace.id === position.trace_id,
        );
        if (traceIndex > -1) {
          commit(COMPLETE_TRACE, { traceIndex, position });
        } else {
          commit(CREATE_TRACE, { position });
        }
      });
    },
  },
};
