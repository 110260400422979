import axios from 'axios';

import { skipOldPromiseResults } from '@/services/api.helper';

const qs = require('qs');

const SITE_API_URL = process.env.VUE_APP_SITE_API_URL;

class APIService {
  constructor() {
    this._instance = axios.create({ baseURL: SITE_API_URL });
    this.requestAndSkipOldResponses = skipOldPromiseResults(
      (args) => this._instance.request(args),
    );
  }

  setLanguage(language) {
    if (language === 'en_GB') {
      const urlSplit = SITE_API_URL.split('/');
      const index = urlSplit.findIndex((el) => el === 'api');
      urlSplit.splice(index, 0, 'en'); // Insert language in split list
      const protocol = `${urlSplit.shift()}//`;
      urlSplit.shift(); // Remove "" from split list
      const englishSiteApiUrl = protocol + urlSplit.join('/');
      this._instance.defaults.baseURL = englishSiteApiUrl;
    } else {
      this._instance.defaults.baseURL = SITE_API_URL;
    }
  }

  setInterceptorRequestUserClient() {
    this._instance.interceptors.request.use((config) => {
      const result = { ...config };
      let userClient = 'desktop_browser';
      const { userAgent } = navigator;
      if (userAgent
        && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
          userAgent,
        )
      ) {
        userClient = 'mobile_browser';
      }
      result.headers['User-Client'] = userClient;
      return result;
    });
  }

  /**
   * Set the current interceptor for JWT Authorization header
   *
   * @param {Function} interceptor
   * Function adding Authorization JWT
   */
  setInterceptorRequestJWT(interceptor) {
    this._interceptor = interceptor;
    this._addCurrentInterceptor();
  }

  /**
   * Set the interceptor to be used when signature expire
   *
   * @param {Function} manageExpiredSignature
   * Function to manage application state when signature expire
   */
  setInterceptorResponseExpiredSignature(manageExpiredSignature) {
    this._instance.interceptors.response.use(
      (response) => response,
      (error) => {
        manageExpiredSignature(error);
        return Promise.reject(error);
      },
    );
  }

  setInterceptorApiErrors(handleApiError) {
    this._instance.interceptors.response.use(
      (response) => response,
      (error) => {
        handleApiError(error);
        return Promise.reject(error);
      },
    );
  }

  /**
   * Add the current interceptor for JWT header in the axios interceptors
   */
  _addCurrentInterceptor() {
    this._interceptorTokenID = this._instance.interceptors.request.use(this._interceptor);
  }

  /**
   * Remove the current interceptor for JWT header in the axios interceptors
   */
  _removeCurrentInspector() {
    this._instance.interceptors.request.eject(this._interceptorTokenID);
  }

  /**
   * Token endpoints, no need of authent
   */
  refreshToken(token) {
    this._removeCurrentInspector();
    const request = this._instance.request({
      method: 'post',
      url: 'refresh',
      data: { token },
    });
    this._addCurrentInterceptor();
    return request;
  }

  verifyToken(token) {
    this._removeCurrentInspector();
    const request = this._instance.request({
      method: 'post',
      url: 'verify',
      data: { token },
    });
    this._addCurrentInterceptor();
    return request;
  }

  saveNotificationToken(token) {
    return this._instance.request({
      errorHandle: false,
      method: 'post',
      url: 'register-notif-token/',
      data: {
        registration_id: token,
        type: 'web',
      },
    });
  }

  /**
   * User endpoints
   */
  login(username, password) {
    return this._instance.request({
      method: 'post',
      url: 'login',
      data: {
        username,
        password,
      },
    });
  }

  loginToken({ sesame }) {
    return this._instance.request({
      method: 'get',
      url: 'visitor-token',
      params: { sesame },
    });
  }

  generateDjangoSesame(uuid, idSelected) {
    return this._instance.request({
      method: 'get',
      url: 'generate-visitor-token',
      params: { uuid, idSelected },
    });
  }

  getWebsocketToken() {
    return this._instance.request({
      method: 'post',
      url: 'users/notification-token',
    });
  }

  sendWebsocketLog(connexion_succeded) {
    return this._instance.request({
      method: 'post',
      url: 'users/websocket-connexion',
      data: { connexion_succeded },
    });
  }

  refreshUserData(id) {
    return this._instance.request({
      method: 'get',
      url: `users/${id}`,
    });
  }

  getNotifications({ last_n_days }) {
    return this._instance.request({
      method: 'get',
      url: 'notifications',
      params: { last_n_days },
    });
  }

  markNotificationsRead({ ids = [] }) {
    return this._instance.request({
      method: 'post',
      url: 'notifications/mark_as_read',
      data: { ids },
    });
  }

  /* eslint camelcase: 0 */
  getApprovals({
    ordering,
    offset,
    limit,
    status,
    is_closed,
    exploitant_id,
    date_min,
    date_max,
    q,
    with_id,
    tactical_contact_required,
    take_off_authorization,
    en_route,
    inside_impacting_zone,
    only_approvals_to_process,
  } = {}) {
    return this.requestAndSkipOldResponses({
      key: 'GET_APPROVALS',
      args: {
        method: 'get',
        url: 'approvals',
        params: {
          ordering,
          offset,
          limit,
          status,
          is_closed,
          exploitant_id,
          date_min,
          date_max,
          q,
          with_id,
          tactical_contact_required,
          take_off_authorization,
          en_route,
          inside_impacting_zone,
          only_approvals_to_process,
        },
      },
    });
  }

  getApprovalDetail(id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${id}`,
    });
  }

  getIntersectingApprovals(id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${id}/intersecting_approvals`,
    });
  }

  getApprovalsGeoCollection({
    ordering,
    status,
    is_closed,
    exploitant_id,
    date_min,
    date_max,
    q,
    tactical_contact_required,
    take_off_authorization,
    en_route,
    inside_impacting_zone,
    only_approvals_to_process,
  } = {}) {
    return this.requestAndSkipOldResponses({
      key: 'GET_APPROVALS_COLLECTION',
      args: {
        method: 'get',
        url: 'approvals',
        params: {
          ordering,
          geom: 'collection',
          status,
          is_closed,
          exploitant_id,
          date_min,
          date_max,
          q,
          tactical_contact_required,
          take_off_authorization,
          en_route,
          inside_impacting_zone,
          no_limit: true,
          only_approvals_to_process,
        },
      },
    });
  }

  markApprovalAsEnRoute({ id, enRoute }) {
    return this._instance.request({
      method: 'patch',
      url: `approvals/${id}/mark_as_en_route`,
      data: { en_route_by_superviser: enRoute },
    });
  }

  getMapLayers() {
    return this._instance.request({
      method: 'get',
      url: 'map-layers',
    });
  }

  getConstraintsAtPoint({ lng, lat }) {
    return this._instance.request({
      method: 'get',
      url: 'constraints_at_point',
      params: { lng, lat },
    });
  }

  getConstraintsForClientStructure(id) {
    return this._instance.request({
      method: 'get',
      url: `client-structures/${id}/constraints`,
    });
  }

  clientStructuresGeocoding({ code, limit = 5 }) {
    return this._instance.request({
      method: 'get',
      url: 'client-structures/geocoding',
      params: {
        code,
        limit,
      },
    });
  }

  getEmailRequest(flightId, structureId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/email_requests`,
      params: { structureId },
    });
  }

  sendConstraintEmailRequest(id, data, headers) {
    return this._instance.request({
      method: 'post',
      url: `flights/${id}/email_request_constraint`,
      data,
      headers,
    });
  }

  createApprovalDecision(id, data) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${id}/decision`,
      data,
    });
  }

  updateApprovalDecision(id, data) {
    return this._instance.request({
      method: 'patch',
      url: `decisions/${id}`,
      data,
    });
  }

  validateProtocolSheet(approvalId, data) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${approvalId}/validate_protocol_sheet`,
      data,
    });
  }

  approvalMessage(id, data) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${id}/message`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  updateApprovalATSNote(id, atsNote) {
    return this._instance.request({
      method: 'patch',
      url: `approvals/${id}/update-details`,
      data: { ats_internal_note: atsNote },
    });
  }

  cancelApprovalRequest(approvalId) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${approvalId}/cancel`,
    });
  }

  updateExploitation(id, payload) {
    return this._instance.request({
      method: 'patch',
      url: `exploitants/${id}`,
      data: payload,
    });
  }

  addAlphaTangoUid(exploitantId, alphatangoUid) {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/add_alphatango_uid`,
      data: { alphatango_uid: alphatangoUid },
    });
  }

  removeAlphaTangoUid(exploitantId, alphatangoUid) {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/remove_alphatango_uid`,
      data: { alphatango_uid: alphatangoUid },
    });
  }

  changeGeneralistFlightTimes(exploitantId, data) {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/change_generalist_flight_times`,
      data,
    });
  }

  getCotations() {
    return this._instance.request({
      method: 'get',
      url: 'cotations',
    });
  }

  getExploitants() {
    return this._instance.request({
      method: 'get',
      url: 'exploitants',
    });
  }

  getExploitationDetails(id) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${id}`,
    });
  }

  getSubscriberActivityStats({
    dates_range,
    is_archived,
    pilots,
    tags,
    sub_exploitant_id,
  }) {
    return this._instance.request({
      method: 'get',
      url: 'flights/activity_stats',
      params: {
        dates_range,
        is_archived,
        pilots,
        tags,
        sub_exploitant_id,
      },
    });
  }

  getDrones({ subExploitantId }) {
    return this._instance.request({
      method: 'get',
      url: 'drones',
      params: { sub_exploitant_id: subExploitantId },
    });
  }

  getSimplifiedDrones({ active = null, subExploitantId }) {
    const params = { sub_exploitant_id: subExploitantId };
    if (active !== null) {
      params.active = active;
    }
    return this._instance.request({
      method: 'get',
      url: 'drones/simplified_drones',
      params,
    });
  }

  searchDrones({ q }) {
    return this.requestAndSkipOldResponses({
      key: 'SEARCH_DRONES',
      args: {
        method: 'get',
        url: 'drones',
        params: {
          q,
          ordering: '-id',
        },
      },
    });
  }

  getAlphaTangoExploitantNames() {
    return this._instance.request({
      method: 'get',
      url: 'drones/get-alphatango-exploitant-names',
    });
  }

  importDronesAlphaTango(alphatango_uid = null) {
    return this._instance.request({
      method: 'post',
      url: 'drones/import-alpha-tango-api',
      data: { alphatango_uid },
    });
  }

  inactivatedDrones(ids) {
    return this._instance.request({
      method: 'post',
      url: 'drones/inactivate',
      data: { ids },
    });
  }

  getPilots({ subExploitantId }) {
    return this._instance.request({
      method: 'get',
      url: 'pilots',
      params: { sub_exploitant_id: subExploitantId },
    });
  }

  getSimplifiedPilots({ isArchived = null, subExploitantId }) {
    const params = { sub_exploitant_id: subExploitantId };
    if (isArchived !== null) {
      params.is_archived = isArchived;
    }
    return this._instance.request({
      method: 'get',
      url: 'pilots/simplified_pilots',
      params,
    });
  }

  getFlights({
    ordering,
    offset,
    limit,
    status,
    is_closed,
    date_min,
    date_max,
    q,
    with_id,
    is_archived,
    is_entrusted,
    contact,
    pilots_category,
    pilots,
    drones,
    tags,
    sub_exploitant_id,
    current_week,
  } = {}) {
    let isArchived = is_archived;
    if (isArchived === true) { isArchived = 'True'; }
    if (isArchived === false) { isArchived = 'False'; }
    let pilotsList = null;
    if (pilots.length) { pilotsList = pilots.join(); }
    let dronesList = null;
    if (drones.length) { dronesList = drones.join(); }
    let tagsList = null;
    if (tags.length) { tagsList = tags.join(); }
    return this.requestAndSkipOldResponses({
      key: 'GET_FLIGHTS',
      args: {
        method: 'get',
        url: 'flights',
        params: {
          ordering,
          offset,
          limit,
          status,
          is_closed,
          date_min,
          date_max,
          q,
          with_id,
          is_archived: isArchived,
          was_entrusted: is_entrusted === false ? null : 'True',
          contact,
          pilots_category,
          pilots: pilotsList,
          drones: dronesList,
          tags: tagsList,
          sub_exploitant_id,
          current_week: current_week === true ? 'True' : null,
        },
        paramsSerializer(params) {
          const paramsNotNull = {};
          Object.keys(params).forEach((key) => {
            if (params[key] !== null) {
              paramsNotNull[key] = params[key];
            }
          });
          return qs.stringify(paramsNotNull, { arrayFormat: 'repeat' });
        },
      },
    });
  }

  getFlightsIds({
    ordering,
    offset,
    limit,
    status,
    is_closed,
    date_min,
    date_max,
    q,
    with_id,
    is_archived,
    is_entrusted,
    contact,
    pilots_category,
    pilots,
    drones,
    tags,
    sub_exploitant_id,
    current_week,
  } = {}) {
    let isArchived = is_archived;
    if (isArchived === true) { isArchived = 'True'; }
    if (isArchived === false) { isArchived = 'False'; }
    let pilotsList = null;
    if (pilots.length) { pilotsList = pilots.join(); }
    let dronesList = null;
    if (drones.length) { dronesList = drones.join(); }
    let tagsList = null;
    if (tags.length) { tagsList = tags.join(); }
    return this.requestAndSkipOldResponses({
      key: 'GET_FLIGHTS_IDS',
      args: {
        method: 'get',
        url: 'flights/flights_ids',
        params: {
          ordering,
          offset,
          limit,
          status,
          is_closed,
          date_min,
          date_max,
          q,
          with_id,
          is_archived: isArchived,
          was_entrusted: is_entrusted === false ? null : 'True',
          contact,
          pilots_category,
          pilots: pilotsList,
          drones: dronesList,
          tags: tagsList,
          sub_exploitant_id,
          current_week: current_week === true ? 'True' : null,
        },
        paramsSerializer(params) {
          const paramsNotNull = {};
          Object.keys(params).forEach((key) => {
            if (params[key] !== null) {
              paramsNotNull[key] = params[key];
            }
          });
          return qs.stringify(paramsNotNull, { arrayFormat: 'repeat' });
        },
      },
    });
  }

  searchFlights({ q }) {
    return this.requestAndSkipOldResponses({
      key: 'SEARCH_FLIGHTS',
      args: {
        method: 'get',
        url: 'flights',
        params: {
          q,
          ordering: '-id',
        },
      },
    });
  }

  getFlightsMetaActivityDomains() {
    return this._instance.request({
      method: 'get',
      url: 'flights/meta/activity_domains',
    });
  }

  getFlightDetails(id, { no_geometry = true }) {
    let params;
    if (no_geometry) {
      params = { no_geometry };
    } else {
      params = {};
    }
    return this._instance.request({
      method: 'get',
      url: `flights/${id}`,
      params,
    });
  }

  getFlightAreasDetails(id, structureId = null) {
    return this._instance.request({
      method: 'get',
      url: `flights/${id}/areas`,
      params: { structureId },
    });
  }

  flightExclusionZoneGeometry(id, data) {
    return this.requestAndSkipOldResponses(
      {
        key: 'RETRIEVE_EXCLUSION_ZONE',
        args: {
          method: 'post',
          url: `flights/${id}/exclusion_area?with_geometry=false`,
          data,
        },
      },
    );
  }

  downloadFlightExclusionAreaKML(flightId, data) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/exclusion_area_kml`,
      responseType: 'blob',
      data,
    });
  }

  uploadFlightExclusionZone(id, data) {
    return this._instance.request({
      method: 'post',
      url: `flights/${id}/upload_exclusion_zone`,
      data,
    });
  }

  downloadFlightExclusionZone(id) {
    return this._instance.request({
      method: 'get',
      url: `flights/${id}/download_exclusion_zone`,
      responseType: 'blob',
    });
  }

  authorityDownloadFlightExclusionZone(id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${id}/download_exclusion_zone`,
      responseType: 'blob',
    });
  }

  getFlightDocuments(id) {
    return this._instance.request({
      method: 'get',
      url: `flights/${id}/documents`,
    });
  }

  getExploitantDocuments(id) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${id}/documents`,
    });
  }

  uploadDocument(formData) {
    return this._instance.request({
      method: 'put',
      url: 'documents/upload',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  }

  downloadDocument(documentId, eventId = null) {
    return this._instance.request({
      method: 'get',
      url: `documents/${documentId}/download?event-id=${eventId}`,
      responseType: 'blob',
    });
  }

  downloadFlightZipDocuments(flightId, params) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/download_zip_documents`,
      params,
      responseType: 'blob',
    });
  }

  deleteDocument(documentId) {
    return this._instance.request({
      method: 'delete',
      url: `documents/${documentId}`,
    });
  }

  getConstraintsAnalysis(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/constraints_analysis`,
    });
  }

  getGeometryConstraintsAnalysis(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/geometry_constraints_analysis`,
    });
  }

  getNotamsAnalysis(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/notams_analysis`,
    });
  }

  getGeometryNotamsAnalysis(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/geometry_notams_analysis`,
    });
  }

  getConstraintCapabilities(flightId, constraintId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/constraints/${constraintId}/capabilities`,
    });
  }

  getVerifiedConstraints() {
    return this._instance.request({
      method: 'get',
      url: 'flights/verified_constraints',
    });
  }

  getPrefectureNotifications(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/prefecture_notifications`,
    });
  }

  getArmyNotifications(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/army_notifications`,
    });
  }

  sendFlightsExportRequest() {
    return this._instance.request({
      method: 'post',
      url: 'flights/send-flights-export',
    });
  }

  getFlightsGeoCollection({
    offset,
    limit,
    ordering,
    status,
    is_closed,
    date_min,
    date_max,
    q,
    is_archived,
    is_entrusted,
    contact,
    pilots_category,
    pilots,
    drones,
    tags,
    sub_exploitant_id,
    current_week,
  } = {}) {
    let isArchived = is_archived;
    if (isArchived === true) { isArchived = 'True'; }
    if (isArchived === false) { isArchived = 'False'; }
    let pilotsList = null;
    if (pilots.length) { pilotsList = pilots.join(); }
    let dronesList = null;
    if (drones.length) { dronesList = drones.join(); }
    let tagsList = null;
    if (tags.length) { tagsList = tags.join(); }
    return this.requestAndSkipOldResponses({
      key: 'GET_FLIGHTS_COLLECTION',
      args: {
        method: 'get',
        url: 'flights',
        params: {
          geom: 'collection',
          offset,
          limit,
          ordering,
          status,
          is_closed,
          date_min,
          date_max,
          q,
          is_archived: isArchived,
          was_entrusted: is_entrusted === false ? null : 'True',
          contact,
          pilots_category,
          pilots: pilotsList,
          drones: dronesList,
          tags: tagsList,
          sub_exploitant_id,
          no_limit: true,
          current_week: current_week === true ? 'True' : null,
        },
        paramsSerializer(params) {
          const paramsNotNull = {};
          Object.keys(params).forEach((key) => {
            if (params[key] !== null) {
              paramsNotNull[key] = params[key];
            }
          });
          return qs.stringify(paramsNotNull, { arrayFormat: 'repeat' });
        },
      },
    });
  }

  getUsers() {
    return this._instance.request({
      method: 'get',
      url: 'users',
    });
  }

  hijackUser({ userId }) {
    return this._instance.request({
      method: 'post',
      url: 'hijack',
      data: { user_id: userId },
    });
  }

  createAccount(payload = {}) {
    return this._instance.request({
      method: 'post',
      url: 'users-signup',
      data: payload,
    });
  }

  modifyUser(id, payload = {}) {
    return this._instance.request({
      method: 'patch',
      url: `users/${id}`,
      data: payload,
    });
  }

  resetPassword(payload = {}) {
    return this._instance.request({
      method: 'post',
      url: 'password_reset/',
      data: payload,
    });
  }

  resetPasswordConfirm(payload = {}) {
    return this._instance.request({
      method: 'post',
      url: 'password_reset/confirm/',
      data: payload,
    });
  }

  nightFlyingTimes(center, dateStart, dateEnd) {
    return this.requestAndSkipOldResponses({
      key: 'NIGHT_FLYING_TIMES',
      args: {
        method: 'post',
        url: 'flights/night_flying_times',
        data: {
          center,
          date_start: dateStart,
          date_end: dateEnd,
        },
      },
    });
  }

  createFlight(flight = {}) {
    return this._instance.request({
      method: 'post',
      url: 'flights',
      data: flight,
    });
  }

  createGeneralistFlight(flight) {
    return this._instance.request({
      method: 'post',
      url: 'flights/create_generalist_mission',
      data: flight,
    });
  }

  updateFlight(id, flight = {}) {
    return this._instance.request({
      method: 'patch',
      url: `flights/${id}`,
      data: flight,
    });
  }

  updateGeneralistFlight(id, flight) {
    return this._instance.request({
      method: 'patch',
      url: `flights/${id}/update_generalist_mission`,
      data: flight,
    });
  }

  classifyFlights(flights) {
    return this._instance.request({
      method: 'post',
      url: 'flights/bulk_classify',
      data: flights,
    });
  }

  deleteFlight(id) {
    return this._instance.request({
      method: 'delete',
      url: `flights/${id}`,
    });
  }

  checkCanTakeOff(id) {
    return this._instance.request({
      method: 'get',
      url: `flights/${id}/check_can_take_off`,
    });
  }

  checkFlightsCanBeDeleted(ids) {
    return this._instance.request({
      method: 'post',
      url: 'flights/bulk_check_can_be_deleted',
      data: ids,
    });
  }

  deleteFlights(ids) {
    return this._instance.request({
      method: 'post',
      url: 'flights/bulk_delete',
      data: ids,
    });
  }

  takeOffFlight(id) {
    return this._instance.request({
      method: 'post',
      url: `flights/${id}/take_off`,
    });
  }

  landFlight(id, duration) {
    return this._instance.request({
      method: 'post',
      url: `flights/${id}/land`,
      data: { duration },
    });
  }

  getFlightAuthorizations(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/flight_authorizations`,
    });
  }

  flightBulkImport(payload = {}) {
    return this._instance.request({
      method: 'post',
      url: 'flights/bulk_import',
      data: payload,
    });
  }

  createLivestreamEmail(email) {
    return this._instance.request({
      method: 'post',
      url: 'livestream-emails',
      data: { email },
    });
  }

  deleteLivestreamEmail(livestreamEmailId) {
    return this._instance.request({
      method: 'delete',
      url: `livestream-emails/${livestreamEmailId}`,
    });
  }

  updateContactSettings(contactId, data) {
    return this._instance.request({
      method: 'patch',
      url: `contact-settings/${contactId}`,
      data,
    });
  }

  createApproval(approval = {}) {
    return this._instance.request({
      method: 'post',
      url: 'approvals',
      data: approval,
    });
  }

  deleteApproval(id) {
    return this._instance.request({
      method: 'delete',
      url: `approvals/${id}`,
    });
  }

  createPilot(pilot = {}) {
    return this._instance.request({
      method: 'post',
      url: 'pilots',
      data: pilot,
    });
  }

  getPilotDetails(id) {
    return this._instance.request({
      method: 'get',
      url: `pilots/${id}`,
    });
  }

  updatePilot(id, pilot = {}) {
    return this._instance.request({
      method: 'patch',
      url: `pilots/${id}`,
      data: pilot,
    });
  }

  canPilotBeDeleted(id) {
    return this._instance.request({
      method: 'get',
      url: `pilots/${id}/can_be_deleted`,
    });
  }

  deletePilot(id) {
    return this._instance.request({
      method: 'delete',
      url: `pilots/${id}`,
    });
  }

  createDrone(drone = {}) {
    return this._instance.request({
      method: 'post',
      url: 'drones',
      data: drone,
    });
  }

  updateDrone(id, drone = {}) {
    return this._instance.request({
      method: 'patch',
      url: `drones/${id}`,
      data: drone,
    });
  }

  canDroneBeDeleted(id) {
    return this._instance.request({
      method: 'get',
      url: `drones/${id}/can_be_deleted`,
    });
  }

  deleteDrone(id) {
    return this._instance.request({
      method: 'delete',
      url: `drones/${id}`,
    });
  }

  getStructuresGeometries() {
    return this._instance.request({
      method: 'get',
      url: 'structures-geometries',
    });
  }

  getAllStructuresGeometries() {
    return this._instance.request({
      method: 'get',
      url: 'structures-geometries/all',
    });
  }

  getAuthorityAIPGeometries() {
    return this._instance.request({
      method: 'get',
      url: 'structures-geometries/aip',
    });
  }

  getAuthorityStructures() {
    return this._instance.request({
      method: 'get',
      url: 'structures-authority',
    });
  }

  getAuthorityLayers() {
    return this._instance.request({
      method: 'get',
      url: 'custom-layers',
    });
  }

  createCustomLayer(layerData) {
    return this._instance.request({
      method: 'post',
      url: 'custom-layers',
      data: layerData,
    });
  }

  editCustomLayer(layerId, layerData) {
    return this._instance.request({
      method: 'patch',
      url: `custom-layers/${layerId}`,
      data: layerData,
    });
  }

  deleteCustomLayer(layerId) {
    return this._instance.request({
      method: 'delete',
      url: `custom-layers/${layerId}`,
    });
  }

  editLayer(identifier, opacity, defaultVisibility) {
    return this._instance.request({
      method: 'patch',
      url: 'map-layers-settings/edit_layer',
      data: {
        identifier,
        opacity,
        default_visibility: defaultVisibility,
      },
    });
  }

  createCustomDataLayer(layerData) {
    return this._instance.request({
      method: 'post',
      url: 'map-layers/upload_custom_layer_map',
      data: layerData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  editCustomDataLayer(layerData) {
    return this._instance.request({
      method: 'patch',
      url: 'map-layers/edit_custom_layer_map',
      data: layerData,
    });
  }

  deleteCustomDataLayer(identifier) {
    return this._instance.request({
      method: 'delete',
      url: 'map-layers/delete_custom_layer_map',
      data: { identifier },
    });
  }

  getMobileNetworkLayers() {
    return this._instance.request({
      method: 'get',
      url: 'map-layers/get_mobile_network_layers',
    });
  }

  addMobileNetworkLayers(layersIds) {
    return this._instance.request({
      method: 'post',
      url: 'map-layers/add_mobile_network_layers',
      data: { layers_ids: layersIds },
    });
  }

  deleteMobileNetworkLayers(identifier) {
    return this._instance.request({
      method: 'delete',
      url: 'map-layers/delete_mobile_network_layers',
      data: { identifier },
    });
  }

  getGeoportailLayers() {
    return this._instance.request({
      method: 'get',
      url: 'map-layers/geoportail_layers',
    });
  }

  addGeoportailLayers(layersIds) {
    return this._instance.request({
      method: 'post',
      url: 'map-layers/add_geoportail_layers',
      data: { layers_ids: layersIds },
    });
  }

  deleteGeoportailLayer(identifier) {
    return this._instance.request({
      method: 'delete',
      url: 'map-layers/remove_geoportail_layer',
      data: { identifier },
    });
  }

  editStructureParameters(id, params) {
    return this._instance.request({
      method: 'patch',
      url: `structures-authority/${id}`,
      data: params,
    });
  }

  downloadHelp() {
    return this._instance.request({
      method: 'get',
      url: 'help',
      responseType: 'blob',
    });
  }

  downloadQuickStartGuide() {
    return this._instance.request({
      method: 'get',
      url: 'help/quick_start_guide',
      responseType: 'blob',
    });
  }

  downloadApprovalWordSheet(app_id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${app_id}/download_word`,
      responseType: 'blob',
    });
  }

  downloadApprovalPDFSheet(app_id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${app_id}/download_pdf`,
      responseType: 'blob',
    });
  }

  getFlightApprovals(flightId, structureId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/approvals`,
      params: { structure_id: structureId },
    });
  }

  postFlightConstraintsCreation(flightId) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/flight_constraints`,
    });
  }

  checkFlightCanBeEntrusted(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/check_can_be_entrusted`,
    });
  }

  entrustMission(flightId, entrustInfo) {
    return this._instance.request({
      method: 'patch',
      url: `flights/${flightId}/entrust`,
      data: { info: entrustInfo },
    });
  }

  getFlightConstraintStatus(flightId, constraintId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/constraint_status/${constraintId}`,
    });
  }

  patchFlightConstraint(
    { flightId, constraintId, status = null, note = null },
  ) {
    const data = {};
    if (status) {
      data.status = status;
    }
    if (note !== null) {
      data.note = note;
    }
    return this._instance.request({
      method: 'patch',
      url: `flights/${flightId}/constraints/${constraintId}`,
      data,
    });
  }

  notifyArmy(flightId, notificationData, ignoreAgglomeration) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/notify_army`,
      data: {
        notification_data: notificationData,
        ignore_agglomeration: ignoreAgglomeration,
      },
    });
  }

  notifyPrefecture(flightId, notificationData) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/notify_prefecture`,
      data: { ...notificationData },
    });
  }

  getPrefectureNotificationsInfo(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/get_prefecture_notifications_info`,
    });
  }

  getArmyNotificationsInfo(flightId, dateStart, dateEnd) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/get_army_notifications_info`,
      params: {
        date_start: dateStart,
        date_end: dateEnd,
      },
    });
  }

  generateFlightConstraintSheet(flightId, scenarioDeclared) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/generate_constraint_sheet`,
      data: { scenarioDeclared },
    });
  }

  generateFlightsConstraintSheet(ids, scenarioDeclared) {
    return this._instance.request({
      method: 'post',
      url: 'flights/bulk_generate_constraint_sheet',
      data: {
        ids,
        declared_scenario: scenarioDeclared,
      },
    });
  }

  downloadFlightWordSheet(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/download_word`,
      responseType: 'blob',
    });
  }

  downloadFlightAreaKML(flightId, { no_name, type } = { no_name: false, type: 'kml' }) {
    const params = { type };
    if (no_name) {
      params.no_name = no_name;
    }
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/download_kml_area`,
      responseType: 'blob',
      params,
    });
  }

  downloadFlightsAreaKML(ids, type, exportFile) {
    return this._instance.request({
      method: 'get',
      url: 'flights/bulk_download_kml_area',
      responseType: 'blob',
      params: {
        ids: JSON.stringify(ids),
        type,
        export_file: exportFile,
      },
    });
  }

  cancelArmyNotif(flightId, notificationId) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/cancel_army_notif`,
      data: { notification_id: notificationId },
    });
  }

  cancelPrefectureNotif(flightId, notificationId) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/cancel_prefecture_notif`,
      data: { notification_id: notificationId },
    });
  }

  updateArmyNotifs(flightId, notificationIds, notificationData, ignoreAgglomeration) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/update_army_notifs`,
      data: {
        notification_ids: notificationIds,
        notification_data: notificationData,
        ignore_agglomeration: ignoreAgglomeration,
      },
    });
  }

  updatePrefectureNotifs(flightId, notificationIds, notificationData) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/update_prefecture_notifs`,
      data: {
        notification_ids: notificationIds,
        notification_data: notificationData,
      },
    });
  }

  createApprovalFromFlight({
    flightId,
    constraintId,
    pilotId,
    droneIds,
    approvalDetails,
    flightAreasIds,
    declaredScenario,
    externalPayload,
    activityDeclarationFile,
    prefectureNotifPayload,
    myDocumentsIds,
    attachedFiles,
  }) {
    let data = null;
    let headers = {};
    const externalPayloadWithMyDocuments = externalPayload;
    externalPayloadWithMyDocuments.my_documents = myDocumentsIds;
    if (activityDeclarationFile || attachedFiles.length) {
      data = new FormData();
      data.append('constraint_id', constraintId);
      data.append('pilot_id', pilotId);
      data.append('drone_ids', droneIds);
      data.append('details', approvalDetails);
      data.append('flight_areas_ids', JSON.stringify(flightAreasIds));
      data.append('external_payload', JSON.stringify(externalPayloadWithMyDocuments));
      data.append('activity_declaration_file', activityDeclarationFile);
      data.append('prefecture_notif_payload', prefectureNotifPayload);
      if (declaredScenario) data.append('declared_scenario', declaredScenario);
      attachedFiles.forEach(
        (file, i) => data.append(`document_${i + 1}`, file),
      );
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = {
        constraint_id: constraintId,
        pilot_id: pilotId,
        drone_ids: droneIds,
        details: approvalDetails,
        flight_areas_ids: flightAreasIds,
        external_payload: externalPayloadWithMyDocuments,
        prefecture_notif_payload: prefectureNotifPayload,
      };
      if (declaredScenario) data.declared_scenario = declaredScenario;
      headers = { 'Content-Type': 'application/json' };
    }
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/request_approval`,
      data,
      headers,
    });
  }

  retrySubmitApproval({
    approvalId,
    constraintId,
    pilotId,
    approvalDetails,
    flightAreasIds,
    externalPayload,
    activityDeclarationFile,
    myDocumentsIds,
    attachedFiles,
  }) {
    let data = null;
    let headers = {};
    const externalPayloadWithMyDocuments = externalPayload;
    externalPayloadWithMyDocuments.my_documents = myDocumentsIds;
    if (activityDeclarationFile || attachedFiles.length) {
      data = new FormData();
      data.append('constraint_id', constraintId);
      data.append('pilot_id', pilotId);
      data.append('details', approvalDetails);
      data.append('flight_areas_ids', JSON.stringify(flightAreasIds));
      data.append('external_payload', JSON.stringify(externalPayloadWithMyDocuments));
      data.append('activity_declaration_file', activityDeclarationFile);
      attachedFiles.forEach(
        (file, i) => data.append(`document_${i + 1}`, file),
      );
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = {
        constraint_id: constraintId,
        pilot_id: pilotId,
        details: approvalDetails,
        flight_areas_ids: flightAreasIds,
        external_payload: externalPayloadWithMyDocuments,
      };
      headers = { 'Content-Type': 'application/json' };
    }
    return this._instance.request({
      method: 'post',
      url: `approvals/${approvalId}/retry_external_submit`,
      data,
      headers,
    });
  }

  updateApprovalFromFlight({
    approvalId,
    constraintId,
    pilotId,
    droneIds,
    approvalDetails,
    flightAreasIds,
    declaredScenario,
  }) {
    return this._instance.request({
      method: 'patch',
      url: `approvals/${approvalId}/update_from_flight`,
      data: {
        constraint_id: constraintId,
        pilot_id: pilotId,
        drone_ids: droneIds,
        details: approvalDetails,
        flight_areas_ids: flightAreasIds,
        declared_scenario: declaredScenario,
      },
    });
  }

  getApprovalDecisions(id) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${id}/decisions`,
    });
  }

  getActivationApprovalDecisions(id) {
    return this._instance.request({
      method: 'get',
      url: `activations/${id}/get_approval_decisions`,
    });
  }

  getFlightStatus({ flightId }) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/status`,
    });
  }

  createCheckoutSession(code) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/create-checkout-session',
      data: { code },
    });
  }

  getPaymentMethodDetails() {
    return this._instance.request({
      method: 'get',
      url: 'subscriptions/payment-method-details',
    });
  }

  changePaymentMethod() {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/change-payment-method',
    });
  }

  previewChangeSubscriptionPlan({ code, promo_code, remove_pilot, add_pilot }) {
    return this._instance.request({
      method: 'get',
      url: 'subscriptions/preview-change-subscription-plan',
      params: {
        plan_code: code,
        promo_code,
        add_pilot,
        remove_pilot,
      },
    });
  }

  changeSubscriptionPlan({ code, quantity, promo_code }) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/change-subscription-plan',
      data: { code, quantity, promo_code },
    });
  }

  cancelSubscription() {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/cancel-subscription',
    });
  }

  reactivateSubscription() {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/reactivate-subscription',
    });
  }

  previewSubscriptionInvoice(code, promoCode = null, affiliateCode = null) {
    return this._instance.request({
      method: 'get',
      url: 'subscriptions/preview-subscription-invoice',
      params: {
        plan_code: code,
        promo_code: promoCode,
        affiliate_code: affiliateCode,
      },
    });
  }

  previewNextInvoice() {
    return this._instance.request({
      method: 'get',
      url: 'subscriptions/preview-next-invoice',
    });
  }

  createApprovalInvoice(approvalId) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/create-approval-invoice',
      data: { approval_id: approvalId },
    });
  }

  confirmApprovalPaymentInvoice(
    paymentMethodId,
    invoiceId,
    description,
    approvalId,
    approvalPilotId,
    approvalDroneIds,
    approvalDetails,
    flightAreasIds,
    declaredScenario,
    structureId,
    billingInformation,
  ) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/confirm-approval-payment-invoice',
      data: {
        payment_method_id: paymentMethodId,
        invoice_id: invoiceId,
        description,
        approval_id: approvalId,
        pilot_id: approvalPilotId,
        drone_ids: approvalDroneIds,
        details: approvalDetails,
        flight_areas_ids: flightAreasIds,
        declared_scenario: declaredScenario,
        structure_id: structureId,
        billing_information: billingInformation,
      },
    });
  }

  createSubscription({
    plan_code,
    quantity,
    promo_code,
    payment_method_id,
    expected_total,
    affiliate_code,
  }) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/create-subscription',
      data: {
        plan_code,
        quantity,
        promo_code,
        affiliate_code,
        payment_method_id,
        expected_total,
      },
    });
  }

  getCheckoutSessionStatus(checkoutId) {
    return this._instance.request({
      method: 'get',
      url: `checkout-sessions/${checkoutId}/status`,
    });
  }

  checkValidationTermsConditions(structureId) {
    return this._instance.request({
      method: 'get',
      url: 'validate-conditions/has_validate',
      params: { structureId },
    });
  }

  postValidationTermsConditions(data) {
    return this._instance.request({
      method: 'post',
      url: 'validate-conditions',
      data,
    });
  }

  downloadPublicTipDocument(flightId, documentId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/public_tip_document/${documentId}/download`,
      responseType: 'blob',
    });
  }

  postSuggestionPuclicTips(payload) {
    return this._instance.request({
      method: 'post',
      url: 'structures/suggestion_public_tips',
      data: payload,
    });
  }

  getActivations(
    {
      ordering,
      offset,
      limit,
      geom = false,
      no_limit = false,
      status,
      with_id,
      date_min,
      date_max,
      exploitant_id,
      q,
      inside_impacting_zone,
    },
  ) {
    return this._instance.request({
      method: 'get',
      url: 'activations',
      params: {
        ordering,
        offset,
        limit,
        geom,
        no_limit,
        status,
        with_id,
        date_min,
        date_max,
        exploitant_id,
        q,
        inside_impacting_zone,
      },
    });
  }

  getActivationDetails(id) {
    return this._instance.request({
      method: 'get',
      url: `activations/${id}`,
    });
  }

  updateActivationStatus(id, data) {
    return this._instance.request({
      method: 'post',
      url: `activations/${id}/decision`,
      data,
    });
  }

  updateActivationPeriod(id, data) {
    return this._instance.request({
      method: 'patch',
      url: `activations/${id}/update_period`,
      data,
    });
  }

  postActivation(payload) {
    return this._instance.request({
      method: 'post',
      url: 'activations',
      data: payload,
    });
  }

  updateActivation(activationId, payload) {
    return this._instance.request({
      method: 'patch',
      url: `activations/${activationId}`,
      data: payload,
    });
  }

  activationMessage(id, data) {
    return this._instance.request({
      method: 'post',
      url: `activations/${id}/message`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  markActivationMessagesAsViewed(id, messagesIds) {
    return this._instance.request({
      method: 'post',
      url: `activations/${id}/mark_messages_as_viewed`,
      data: { ids: messagesIds },
    });
  }

  cancelActivation(activationId) {
    return this._instance.request({
      method: 'patch',
      url: `activations/${activationId}/cancel`,
    });
  }

  revalidateApproval(approvalId, data) {
    return this._instance.request({
      method: 'patch',
      url: `approvals/${approvalId}/revalidate`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  synchronizeApprovalExternalDocuments(approvalId) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${approvalId}/synchronize_external_documents`,
    });
  }

  getTakeOffAuthorization(id) {
    return this._instance.request({
      method: 'get',
      url: `take-off-authorizations/${id}`,
    });
  }

  checkCanRequestTakeOffAuthorization(id) {
    return this._instance.request({
      method: 'get',
      url: `take-off-authorizations/${id}/check_can_request`,
    });
  }

  requestTakeOffAuthorization(id, payload) {
    return this._instance.request({
      method: 'patch',
      url: `take-off-authorizations/${id}/request`,
      data: payload,
    });
  }

  sendTakeOffAuthorizationMessage(id, payload) {
    return this._instance.request({
      method: 'post',
      url: `take-off-authorizations/${id}/send_message`,
      data: payload,
    });
  }

  updateTakeOffAuthorizationDecision(id, payload) {
    return this._instance.request({
      method: 'post',
      url: `take-off-authorizations/${id}/decision`,
      data: payload,
    });
  }

  markTakeOffAuthorizationMessagesAsViewed(id, messagesIds) {
    return this._instance.request({
      method: 'post',
      url: `take-off-authorizations/${id}/mark_messages_as_viewed`,
      data: { ids: messagesIds },
    });
  }

  getApprovalsInvoices(structureId) {
    return this._instance.request({
      method: 'get',
      url: `structures-requiring-payment/${structureId}/invoices`,
    });
  }

  getOnboardingInformations(structureId) {
    return this._instance.request({
      method: 'get',
      url: `structures-requiring-payment/${structureId}/onboarding_informations`,
    });
  }

  getOnboardingLink(structureId) {
    return this._instance.request({
      method: 'get',
      url: `structures-requiring-payment/${structureId}/link_complete_onboarding`,
    });
  }

  downloadAuthorityInvoice(structureId, invoiceId) {
    return this._instance.request({
      method: 'get',
      url: `structures-requiring-payment/${structureId}/download_pdf`,
      params: { invoiceId },
      responseType: 'blob',
    });
  }

  getExploitantInvoices(id) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${id}/invoices`,
    });
  }

  downloadExploitantInvoice(exploitantId, invoiceId) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${exploitantId}/invoices/${invoiceId}/download_pdf`,
      responseType: 'blob',
    });
  }

  getStructureStatistics(id, { offset, limit, ordering, q } = {}) {
    return this._instance.request({
      method: 'get',
      url: `structures-statistics/${id}/exploitants`,
      params: {
        offset,
        limit,
        ordering,
        q,
      },
    });
  }

  getStructureGraph(id) {
    return this._instance.request({
      method: 'get',
      url: `structures-statistics/${id}/graph`,
    });
  }

  getApprovalsHistory(id) {
    return this._instance.request({
      method: 'get',
      url: `structures-statistics/${id}/approvals_history`,
    });
  }

  applyApprovalDiscount(method, exploitantId, structureId, discount) {
    return this._instance.request({
      method,
      url: `structures-requiring-payment/${structureId}/exploitants/${exploitantId}/discount`,
      data: { discount },
    });
  }

  deleteApprovalDiscount(exploitantId, structureId) {
    return this._instance.request({
      method: 'delete',
      url: `structures-requiring-payment/${structureId}/exploitants/${exploitantId}/discount`,
    });
  }

  getExploitantDiscount(exploitantId, structureId) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${exploitantId}/structures/${structureId}/discount`,
    });
  }

  createEntrustPaperworkInvoice(flightId) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/create-entrust-paperwork-invoice',
      data: { flight_id: flightId },
    });
  }

  confirmEntrustPaperworkPaymentInvoice(
    paymentMethodId,
    invoiceId,
    description,
    flightId,
    entrustInfo,
    billingInformation,
  ) {
    return this._instance.request({
      method: 'post',
      url: 'subscriptions/confirm-entrust-paperwork-payment-invoice',
      data: {
        payment_method_id: paymentMethodId,
        invoice_id: invoiceId,
        description,
        flight_id: flightId,
        info: entrustInfo,
        billing_information: billingInformation,
      },
    });
  }

  getCustomExploitantStructureLinkNote(structureId, exploitantId) {
    return this._instance.request({
      method: 'get',
      url: `structures-authority/${structureId}/exploitants/${exploitantId}/note`,
    });
  }

  createCustomExploitantStructureLink(exploitantId, structureId, payload) {
    return this._instance.request({
      method: 'post',
      url: `structures-authority/${structureId}/exploitants/${exploitantId}/custom_exploitant_structure_link`,
      data: { payload },
    });
  }

  updateCustomExploitantStructureLink(exploitantId, structureId, payload) {
    return this._instance.request({
      method: 'patch',
      url: `structures-authority/${structureId}/exploitants/${exploitantId}/custom_exploitant_structure_link`,
      data: { payload },
    });
  }

  getExternalActivationCapabilities(
    approvalId, dateStart = null, dateEnd = null, timeStart = null, timeEnd = null,
  ) {
    return this.requestAndSkipOldResponses(
      {
        key: 'GET_EXTERNAL_ACTIVATION_CAPABILITIES',
        args: {
          method: 'get',
          url: `approvals/${approvalId}/activation_capabilities`,
          params: {
            date_start: dateStart,
            date_end: dateEnd,
            time_start: timeStart,
            time_end: timeEnd,
          },
        },
      },
    );
  }

  externalFlightAnalysis(flightId, usspIdentifier) {
    return this._instance.request({
      method: 'get',
      url: 'external-structure-capabilities/external_analysis',
      params: {
        flight_id: flightId,
        ussp_identifier: usspIdentifier,
      },
    });
  }

  getUserSubExploitants() {
    return this._instance.request({
      method: 'get',
      url: 'sub-exploitants',
    });
  }

  getAllSubExploitants() {
    return this._instance.request({
      method: 'get',
      url: 'sub-exploitants/all',
    });
  }

  createSubExploitant(name, alphatangoUID) {
    return this._instance.request({
      method: 'post',
      url: 'sub-exploitants',
      data: {
        name,
        alpha_tango_uid: alphatangoUID,
      },
    });
  }

  updateSubExloitant(subExploitantId, name, alphatangoUID) {
    return this._instance.request({
      method: 'patch',
      url: `sub-exploitants/${subExploitantId}`,
      data: {
        name,
        alpha_tango_uid: alphatangoUID,
      },
    });
  }

  deleteSubExloitant(subExploitantId) {
    return this._instance.request({
      method: 'delete',
      url: `sub-exploitants/${subExploitantId}`,
    });
  }

  getFlyingTimes(sub_exploitant_id) {
    return this._instance.request({
      method: 'get',
      url: 'flying-times',
      params: { sub_exploitant_id },
    });
  }

  createFlyingTime(pilot, date, nbFlights, duration, comment) {
    return this._instance.request({
      method: 'post',
      url: 'flying-times',
      data: {
        contact: pilot,
        date,
        nb_flights: nbFlights,
        duration,
        comment,
      },
    });
  }

  updateFlyingTime(id, pilot, date, nbFlights, duration, comment) {
    return this._instance.request({
      method: 'patch',
      url: `flying-times/${id}`,
      data: {
        contact: pilot,
        date,
        nb_flights: nbFlights,
        duration,
        comment,
      },
    });
  }

  deleteFlyingTime(id) {
    return this._instance.request({
      method: 'delete',
      url: `flying-times/${id}`,
    });
  }

  getFlyingTimeStats(sub_exploitant_id) {
    return this._instance.request({
      method: 'get',
      url: 'flying-times/stats',
      params: { sub_exploitant_id },
    });
  }

  exportFlyingTimes() {
    return this._instance.request({
      method: 'get',
      url: 'flying-times/export',
    });
  }

  getFlightIncidents({ ordering, limit, offset, q, date_start, date_end, sub_exploitant_id }) {
    return this._instance.request({
      method: 'get',
      url: 'flight-incidents',
      params: {
        ordering,
        limit,
        offset,
        q,
        date_start,
        date_end,
        sub_exploitant_id,
      },
    });
  }

  getFlightIncidentDetails(flightIncidentId) {
    return this._instance.request({
      method: 'get',
      url: `flight-incidents/${flightIncidentId}`,
    });
  }

  createFlightIncident(payload, files) {
    let data = null;
    let headers = {};
    if (files.length) {
      data = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key === 'attachments_ids') {
          data.append('attachments_ids', JSON.stringify(payload.attachments_ids));
        } else if (key === 'sub_exploitants_ids') {
          data.append('sub_exploitants_ids', JSON.stringify(payload.sub_exploitants_ids));
        } else {
          data.append(key, payload[key]);
        }
      });
      files.forEach((file, i) => data.append(`document_${i + 1}`, file));
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = payload;
      headers = { 'Content-Type': 'application/json' };
    }
    return this._instance.request({
      method: 'post',
      url: 'flight-incidents',
      data,
      headers,
    });
  }

  updateFlightIncident(flightIncidentId, payload, files) {
    let data = null;
    let headers = {};
    if (files.length) {
      data = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key === 'attachments_ids') {
          data.append('attachments_ids', JSON.stringify(payload.attachments_ids));
        } else if (key === 'sub_exploitants_ids') {
          data.append('sub_exploitants_ids', JSON.stringify(payload.sub_exploitants_ids));
        } else {
          data.append(key, payload[key]);
        }
      });
      files.forEach((file, i) => data.append(`document_${i + 1}`, file));
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = payload;
      headers = { 'Content-Type': 'application/json' };
    }
    return this._instance.request({
      method: 'patch',
      url: `flight-incidents/${flightIncidentId}`,
      data,
      headers,
    });
  }

  exportFlightIncidents({ q, date_start, date_end, sub_exploitant_id }) {
    return this._instance.request({
      method: 'get',
      url: 'flight-incidents/export',
      params: {
        q,
        date_start,
        date_end,
        sub_exploitant_id,
      },
    });
  }

  getFlightIncidentAnnouncement() {
    return this._instance.request({
      method: 'get',
      url: 'exploitants/flight-incident-announcement',
    });
  }

  createFlightIncidentAnnouncement({ text }) {
    return this._instance.request({
      method: 'post',
      url: 'flight-incident-announcements',
      data: { text },
    });
  }

  updateFlightIncidentAnnouncement({ id, text }) {
    return this._instance.request({
      method: 'patch',
      url: `flight-incident-announcements/${id}`,
      data: { text },
    });
  }

  deleteFlightIncidentAnnouncement(id) {
    return this._instance.request({
      method: 'delete',
      url: `flight-incident-announcements/${id}`,
    });
  }

  getSubExploitantPilots(subExploitantId) {
    return this._instance.request({
      method: 'get',
      url: `sub-exploitants/${subExploitantId}/pilots`,
    });
  }

  getSubExploitantDrones(subExploitantId) {
    return this._instance.request({
      method: 'get',
      url: `sub-exploitants/${subExploitantId}/drones`,
    });
  }

  exportJournal() {
    return this._instance.request({
      method: 'get',
      url: 'exploitants/journal',
    });
  }

  getExploitantAccountRecap(exploitantId) {
    return this._instance.request({
      method: 'get',
      url: `exploitants/${exploitantId}/account_recap`,
    });
  }

  requestExploitantAccountDeletion(exploitantId) {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/request_account_deletion`,
    });
  }

  getFlightPropertiesAfterConstraintsCreation(flightId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/properties_after_constraints_creation`,
    });
  }

  warnManagersUpdateSubscription(exploitantId, message = '') {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/request_update_subscription`,
      data: { message },
    });
  }

  getFlightApproval(approvalId, flightApprovalId) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${approvalId}/flight_approval?flightApprovalId=${flightApprovalId}`,
    });
  }

  getOpinionsGeometries({ ordering, isClosed, dateFrom, dateTo, q }) {
    return this._instance.request({
      method: 'get',
      url: 'opinions',
      params: {
        geom: 'collection',
        ordering,
        is_closed: isClosed,
        date_start: dateFrom,
        date_end: dateTo,
        q,
      },
    });
  }

  getOpinions({ ordering, withId, limit, offset, isClosed, dateFrom, dateTo, q }) {
    return this._instance.request({
      method: 'get',
      url: 'opinions',
      params: {
        ordering,
        with_id: withId,
        limit,
        offset,
        is_closed: isClosed,
        date_start: dateFrom,
        date_end: dateTo,
        q,
      },
    });
  }

  getOpinionsEmails() {
    return this._instance.request({
      method: 'get',
      url: 'opinions/emails',
    });
  }

  getOpinionDetails(opinionId) {
    return this._instance.request({
      method: 'get',
      url: `opinions/${opinionId}`,
    });
  }

  getApprovalOpinions(approvalId) {
    return this._instance.request({
      method: 'get',
      url: `approvals/${approvalId}/opinions`,
    });
  }

  createOpinion(payload) {
    return this._instance.request({
      method: 'post',
      url: 'opinions',
      data: payload,
    });
  }

  sendOpinionMessage(opinionId, payload = {}, attachedFiled = []) {
    let data = null;
    let headers = {};
    if (attachedFiled.length) {
      data = new FormData();
      Object.keys(payload).forEach(
        (key) => data.append(key, payload[key]),
      );
      attachedFiled.forEach(
        (file, i) => data.append(`document_${i + 1}`, file),
      );
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = payload;
      headers = { 'Content-Type': 'application/json' };
    }
    return this._instance.request({
      method: 'patch',
      url: `opinions/${opinionId}/messages`,
      data,
      headers,
    });
  }

  updateOpinionStatus(opinionId, status, message) {
    return this._instance.request({
      method: 'patch',
      url: `opinions/${opinionId}`,
      data: {
        status,
        message,
      },
    });
  }

  getSupervisionsGeometries({ ordering, dateFrom, dateTo, isClosed }) {
    return this.requestAndSkipOldResponses(
      {
        key: 'GET_SUPERVISIONS_COLLECTION',
        args: {
          method: 'get',
          url: 'supervisions',
          params: {
            geom: 'collection',
            ordering,
            date_start: dateFrom,
            date_end: dateTo,
            is_closed: isClosed,
          },
        },
      },
    );
  }

  getSupervisions({ ordering, limit, offset, dateFrom, dateTo, isClosed }) {
    return this.requestAndSkipOldResponses(
      {
        key: 'GET_SUPERVISIONS',
        args: {
          method: 'get',
          url: 'supervisions',
          params: {
            ordering,
            limit,
            offset,
            date_start: dateFrom,
            date_end: dateTo,
            is_closed: isClosed,
          },
        },
      },
    );
  }

  getLivestreamDemoVideoUrl() {
    return this._instance.request({
      method: 'get',
      url: 'livestreams/get_demo_video_url',
    });
  }

  getLivestreams() {
    return this._instance.request({
      method: 'get',
      url: 'livestreams',
    });
  }

  updateLivestream(id, livestreamData = {}) {
    return this._instance.request({
      method: 'patch',
      url: `livestreams/${id}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: livestreamData,
    });
  }

  reloadLivestreamPublicLink(id) {
    return this._instance.request({
      method: 'patch',
      url: `livestreams/${id}/reload_public_link`,
    });
  }

  getLivestreamByName(name) {
    return this._instance.request({
      method: 'get',
      url: `livestreams/get_by_name/${name}`,
    });
  }

  deleteLivestream(livestreamId) {
    return this._instance.request({
      method: 'delete',
      url: `livestreams/${livestreamId}`,
    });
  }

  flightLogWeatherEvent(flightId) {
    return this._instance.request({
      method: 'post',
      url: `flights/${flightId}/log_weather_event`,
    });
  }

  logExploitantConnection(exploitantId) {
    return this._instance.request({
      method: 'post',
      url: `exploitants/${exploitantId}/log_connection`,
    });
  }

  versionProjects() {
    return this._instance.request({
      method: 'get',
      url: 'version-projects/get_last_version_projects',
    });
  }

  getLatestAnnouncement() {
    return this._instance.request({
      method: 'get',
      url: 'announcements/last',
    });
  }

  createAnnouncements(text) {
    return this._instance.request({
      method: 'post',
      url: 'announcements',
      data: { text },
    });
  }

  stopDisplayAnnouncement(id) {
    return this._instance.request({
      method: 'patch',
      url: `announcements/${id}/stop_display`,
    });
  }

  deleteAnnouncements() {
    return this._instance.request({
      method: 'delete',
      url: 'announcements/delete_all',
    });
  }

  checkAreaCanBeDeleted(flightId, areaId) {
    return this._instance.request({
      method: 'get',
      url: `flights/${flightId}/check_area_can_be_deleted?areaId=${areaId}`,
    });
  }

  markTrackingAlertsRead(structureId, trackingAlertIds) {
    return this._instance.request({
      method: 'post',
      url: `structures-authority/${structureId}/mark_tracking_alerts_as_read`,
      data: { tracking_alert_ids: trackingAlertIds },
    });
  }

  getTrackerTracesGeoCollection({ ordering, dateFrom, dateTo, q }) {
    return this._instance.request({
      method: 'get',
      url: 'tracker-traces',
      params: {
        geom: 'collection',
        ordering,
        date_start: dateFrom,
        date_end: dateTo,
        q,
      },
    });
  }

  getTrackerTraces({ ordering, withId, limit, offset, dateFrom, dateTo, q }) {
    return this._instance.request({
      method: 'get',
      url: 'tracker-traces',
      params: {
        ordering,
        with_id: withId,
        limit,
        offset,
        date_start: dateFrom,
        date_end: dateTo,
        q,
      },
    });
  }

  getTrackerTraceDetails(id) {
    return this._instance.request({
      method: 'get',
      url: `tracker-traces/${id}`,
    });
  }

  getStructuresImpacting() {
    return this._instance.request({
      method: 'get',
      url: 'structures-impacting',
    });
  }

  updateStructureImpact(id) {
    return this._instance.request({
      method: 'patch',
      url: `structures-impacting/${id}`,
    });
  }

  closeApproval(approvalId) {
    return this._instance.request({
      method: 'patch',
      url: `approvals/${approvalId}/close-approval`,
    });
  }

  markApprovalMessagesAsViewed(id, messagesIds) {
    return this._instance.request({
      method: 'post',
      url: `approvals/${id}/mark_messages_as_viewed`,
      data: { ids: messagesIds },
    });
  }

  getActivationAllowedHours(id) {
    return this._instance.request({
      method: 'get',
      url: `activations/${id}/allowed_hours`,
    });
  }

  getFlightTagsNames() {
    return this._instance.request({
      method: 'get',
      url: 'flight-tags/list_names',
    });
  }

  getFlightTags() {
    return this._instance.request({
      method: 'get',
      url: 'flight-tags',
    });
  }

  deleteFlightTag(id) {
    return this._instance.request({
      method: 'delete',
      url: `flight-tags/${id}`,
    });
  }

  getFlightTagConstraintTypes() {
    return this._instance.request({
      method: 'get',
      url: 'flight-tags/constraint_types',
    });
  }

  createFlightTag(data) {
    return this._instance.request({
      method: 'post',
      url: 'flight-tags',
      data,
    });
  }

  updateFlightTag(tagId, data) {
    return this._instance.request({
      method: 'patch',
      url: `flight-tags/${tagId}`,
      data,
    });
  }

  getFlightDiscussion(id) {
    return this._instance.request({
      method: 'get',
      url: `flights/${id}/discussion`,
    });
  }

  flightMessage(id, data) {
    return this._instance.request({
      method: 'post',
      url: `flights/${id}/message`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new APIService();
