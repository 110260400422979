<template>
  <div
    class="primary--form"
    :class="{ 'mr-2': !isMobileBreakpoint }"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row
        no-gutters
        class="primary--form__header px-4"
      >
        <v-col
          cols="7"
          sm="8"
          class="text-left"
        >
          <translate v-if="isEditing">Drone update</translate>
          <translate v-else>New drone</translate>
        </v-col>
        <v-col
          cols="5"
          sm="4"
          class="text-right"
          :class="{ 'pr-1': !isMobileBreakpoint }"
        >
          <translate>Cancel</translate>
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="primary--form__container">
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              NAME *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="pr-0 pr-sm-5"
          >
            <v-text-field
              v-model="drone.name"
              :rules="rules.name"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-0 pl-sm-3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              MODEL *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="drone.model_name"
              :rules="rules.modelName"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              MANUFACTURER *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="drone.manufacturer"
              :rules="rules.manufacturer"
              class="p-0"
            />
          </v-col>
          <!-- Specific class -->
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              CLASS
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-select
              v-model="drone.specific_class"
              :items="droneClasses"
              item-text="label"
              item-value="value"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              CATEGORY *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-select
              v-model="drone.category"
              :rules="rules.category"
              :items="droneCategories"
              item-text="label"
              item-value="value"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              SERIAL NUMBER *
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="drone.serial_number"
              :rules="rules.serialNumber"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              ALPHATANGO IDENTIFIER
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="drone.alpha_tango_id"
              type="number"
              prefix="UAS-FR-"
              class="p-0"
              :rules="rules.alphaTangoId"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              IDENTIFICATION MARK
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="drone.identification_mark"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              DJI CONTROLLER NUMBER
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
            <v-row no-gutters>
              <v-text-field
                v-model="drone.dji_controller_id"
                class="p-0"
              />
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              REMOTE ID
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-column"
          >
            <v-select
              v-model="drone.remote_type"
              :items="droneRemoteIdTypes"
              item-text="label"
              item-value="value"
              class="pa-0"
              @change="updateRemoteId()"
            />
            <v-text-field
              v-model="drone.remote_id"
              :rules="rules.remoteId"
              :disabled="!drone.remote_type"
              class="pa-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              WEIGHT
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pr-0 pr-sm-5"
          >
            <v-text-field
              v-model="drone.weight"
              type="number"
              suffix="kg"
              class="p-0"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <span
              class="primary--form__label"
              v-translate
            >
              MAX WIND SPEED
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pr-0 pr-sm-5"
          >
            <v-text-field
              v-model="drone.windspeed"
              type="number"
              suffix="km/h"
              class="p-0"
            />
          </v-col>
          <v-col cols="12">
            <HssAuthorizationForm
              :hss_authorization.sync="drone.hss_authorization"
              :hss_date_start.sync="drone.hss_date_start"
              :hss_date_end.sync="drone.hss_date_end"
            />
          </v-col>
          <v-col
            v-if="subExploitantsAvailable"
            cols="12"
            sm="3"
          >
            <span class="primary--form__label text-uppercase">
              {{ entityName.plural }}
            </span>
          </v-col>
          <v-col
            v-if="subExploitantsAvailable"
            cols="12"
            sm="9"
          >
            <v-autocomplete
              class="p-0"
              :items="subExploitants"
              v-model="drone.subExploitants"
              multiple
              item-text="name"
              item-value="id"
              :no-data-text="$gettext('No result found')"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="drone.captive"
              :label="$gettext('Drone will be use in captive mode?')"
              hide-details
            />
            <v-checkbox
              v-model="drone.active"
              :label="$gettext('Proposing this drone for future missions?')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mt-5 mb-3"
        >
          <v-btn
            v-if="!isEditing"
            block
            class="white--text"
            color="green"
            @click="createDrone()"
            :disabled="!valid"
            :loading="loading"
          >
            <translate>CREATE DRONE</translate>
          </v-btn>
          <v-btn
            v-if="isEditing"
            block
            class="white--text"
            color="green"
            @click="updateDrone()"
            :disabled="!valid"
            :loading="loading"
          >
            <translate>UPDATE DRONE</translate>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import API from '@/services/api';

import { DRONE_CATEGORIES, DRONE_CLASSES, DRONE_REMOTE_ID_TYPE } from '@/settings';

import { APPLICATION_STATUS, SET_STATUS_NS } from '@/store/application';
import { MAP_STATUS, SET_MAP_STATUS_NS } from '@/store/map';

import HssAuthorizationForm from '@/components/Drones/HssAuthorization.vue';

const initialDrone = {
  name: '',
  model_name: '',
  manufacturer: '',
  category: '',
  weight: 1.0,
  serial_number: '',
  identification_mark: '',
  dji_controller_id: '',
  windspeed: '',
  captive: false,
  autopilot_version: '',
  autopilot_manufacturer: '',
  design_attestation: '',
  desgin_attestation_reference: '',
  design_attestation_date: '',
  active: true,
  subExploitants: [],
  alpha_tango_id: '',
  hss_authorization: null,
  hss_date_start: null,
  hss_date_end: null,
  remote_type: '',
  remote_id: '',
  specific_class: '',
};

export default {
  name: 'DroneForm',
  props: { droneToEdit: { type: Object } },
  components: { HssAuthorizationForm },
  data() {
    return {
      valid: true,
      drone: { ...initialDrone },
      rules: {
        name: [
          (v) => !!v || this.$gettext('Drone name is required'),
        ],
        modelName: [
          (v) => !!v || this.$gettext('Drone model is required'),
        ],
        manufacturer: [
          (v) => !!v || this.$gettext('Manufacturer is required'),
        ],
        category: [
          (v) => !!v || this.$gettext('The category is required'),
        ],
        serialNumber: [
          (v) => !!v || this.$gettext('Serial number is required'),
        ],
        alphaTangoId: [
          (v) => v <= 2147483647 || this.$gettext('A more small number is required.'), // backend limit
        ],
        remoteId: [
          (v) => (v?.length >= 19 && v?.length <= 30) || !v || this.$gettext('Invalid format'),
        ],
      },
      droneCategories: DRONE_CATEGORIES,
      droneClasses: DRONE_CLASSES,
      droneRemoteIdTypes: DRONE_REMOTE_ID_TYPE,
      hssDateStartMenu: false,
      hssDateEndMenu: false,
      loading: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    isEditing() {
      return this.droneToEdit !== null;
    },
    subExploitantsAvailable() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
    subExploitants() {
      return this.$store.state.authentication.userSubExploitants;
    },
    showHssAuthorizationInDrone() {
      return (
        this.$store.getters['authentication/hssNotificationsAllowed']
        && !this.$store.getters['exploitants/hasExploitantHssAuthorization']
      );
    },
    entityName() {
      return this.$store.getters['exploitants/entityName'];
    },
  },
  created() {
    if (this.droneToEdit) {
      this.$set(this.$data, 'drone', { ...this.droneToEdit });
      this.drone.subExploitants = this.droneToEdit.sub_exploitants;
    } else {
      this.$set(this.$data, 'drone', { ...initialDrone });
    }
  },
  methods: {
    updateRemoteId() {
      if (!this.drone.remote_type) this.drone.remote_id = '';
    },
    buildDrone() {
      const payload = {};
      // we build payload only with non null values
      // because API trigger error if they're sent
      Object.keys(this.drone).forEach((key) => {
        if (key === 'subExploitants') {
          if (this.subExploitantsAvailable) {
            payload.sub_exploitants = this.subExploitants.filter(
              (s) => this.drone.subExploitants.includes(s.id),
            ).map((s) => s.id);
          } else {
            payload.sub_exploitants = this.subExploitants.map((s) => s.id);
          }
        } else if (this.drone[key]) {
          payload[key] = this.drone[key];
        }
      });
      return payload;
    },
    async createDrone() {
      // we first validate the form before submitting to API
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = this.buildDrone();
        await API.createDrone(payload)
          .then(() => {
            this.showMessage(this.$gettext('Drone created.'), 'success');
            this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    prepareDroneToUpdate() {
      const payload = {};
      Object.keys(this.drone).forEach((key) => {
        if (key === 'subExploitants') {
          payload.sub_exploitants = this.subExploitants.filter(
            (s) => this.drone.subExploitants.includes(s.id),
          ).map((s) => s.id);
        } else if ( // Backend accept only null value for integer type
          ['alpha_tango_id', 'weight', 'windspeed'].includes(key)
          && this.drone[key] === ''
        ) {
          payload[key] = null;
        } else {
          payload[key] = this.drone[key];
        }
      });
      delete payload.documents;
      return payload;
    },
    async updateDrone() {
      // we first validate the form before submitting to API
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = this.prepareDroneToUpdate();
        await API.updateDrone(this.drone.id, payload)
          .then(() => {
            this.showMessage(this.$gettext('Drone updated.'), 'success');
            this.close();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    close() {
      this.$emit('reset-drone-to-edit');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
    },
  },
};
</script>
