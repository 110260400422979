<template>
  <Chat
    :events="activation.events"
    :newMessageDisabled="newMessageDisabled"
    :atSendMessage="sendMessage"
    :maxAttachmentSize="maxAttachmentSize"
    :highlight="highlight"
    :atMarkMessagesAsViewed="(ids) => markActivationMessagesAsViewed(ids)"
    viewedAtEnabled
  />
</template>

<script>
import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB } from '@/settings';

import Chat from '@/components/Chat.vue';

const MAX_ACTIVATION_DOCUMENT_SIZE_MB = MAX_DOCUMENT_SIZE_MB.activation;

export default {
  name: 'ActivationChat',
  components: { Chat },
  props: {
    activation: Object,
    newMessageDisabled: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { maxAttachmentSize: MAX_ACTIVATION_DOCUMENT_SIZE_MB };
  },
  computed: {
    isUserHijacked() {
      return this.$store.state.authentication.hijackedUserId !== null;
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
  },
  methods: {
    buildPayload(message, attachedFiles, myDocuments) {
      const payload = new FormData();
      payload.append('message', message);
      attachedFiles.forEach(
        (file, i) => payload.append(`document_${i + 1}`, file),
      );
      if (myDocuments.length) {
        payload.append('my_documents', JSON.stringify(myDocuments.map((d) => d.documentId)));
      }
      return payload;
    },
    async sendMessage(message, attachedFiles, myDocuments) {
      const payload = this.buildPayload(message, attachedFiles, myDocuments);
      return APIService.activationMessage(this.activation.id, payload).then(({ data }) => data);
    },
    markActivationMessagesAsViewed(ids) {
      if (this.isUserHijacked || (this.isUserAuthority && !this.isAuthorityAdminOrManager)) return;
      APIService.markActivationMessagesAsViewed(this.activation.id, ids);
    },
  },
};
</script>
