import * as turf from '@turf/turf';

import Vue from 'vue';

import APIService from '@/services/api';

/* eslint-disable import/no-cycle */
import { getPageFromNextPreviousURL } from '@/store/application';
import {
  SELECTABLE_FEATURE_KEYS,
  SET_MAP_FEATURE_HOVERED_NS,
  SET_MAP_FEATURE_SELECTED_NS,
} from '@/store/map';

const namespace = 'activations';

const GET_ACTIVATIONS = 'GET_ACTIVATIONS';
const SET_ACTIVATIONS_COLLECTION = 'SET_ACTIVATIONS_COLLECTION';
const SET_ACTIVATION_DETAILS_LOADING = 'SET_ACTIVATION_DETAILS_LOADING';
const SET_ACTIVATION_SELECTED = 'SET_ACTIVATION_SELECTED';
const SET_ACTIVATION_DETAILS = 'SET_ACTIVATION_DETAILS';
const SET_ACTIVATION_STATUS = 'SET_ACTIVATION_STATUS';
const SET_ACTIVATION_HOVER = 'SET_ACTIVATION_HOVER';
const SET_FILTERS = 'SET_FILTERS';
const SET_TABLES_IDS = 'SET_TABLES_IDS';
const SET_PAGINATION = 'SET_PAGINATION';
const RESET_FILTERS = 'RESET_FILTERS';
const RESET_ACTIVATIONS = 'RESET_ACTIVATIONS';
const GET_ACTIVATIONS_GEOMETRY = 'GET_ACTIVATIONS_GEOMETRY';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const GET_ALL_ACTIVATIONS = 'GET_ALL_ACTIVATIONS';
const FETCH_ACTIVATION_COLLECTION = 'FETCH_ACTIVATION_COLLECTION';
const SET_ACTIVATIONS_LOADING = 'SET_ACTIVATIONS_LOADING';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const SET_ACTIVATIONS_COLLECTION_LOADED = 'SET_ACTIVATIONS_COLLECTION_LOADED';

export const SET_ACTIVATION_SELECTED_NS = `${namespace}/${SET_ACTIVATION_SELECTED}`;
export const SET_ACTIVATION_STATUS_NS = `${namespace}/${SET_ACTIVATION_STATUS}`;
export const SET_ACTIVATION_HOVER_NS = `${namespace}/${SET_ACTIVATION_HOVER}`;
export const RESET_ACTIVATIONS_NS = `${namespace}/${RESET_ACTIVATIONS}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const GET_ALL_ACTIVATIONS_NS = `${namespace}/${GET_ALL_ACTIVATIONS}`;
export const SET_ACTIVATION_DETAILS_NS = `${namespace}/${SET_ACTIVATION_DETAILS}`;

const defaultFilters = {
  status: '',
  dateFrom: null,
  dateTo: null,
  exploitantId: '',
  q: null,
  insideImpactingZone: null,
};

export function initialState() {
  return {
    activationsCollection: [],
    activationDetailsLoading: false,
    activationsLoading: false,
    activationsLoaded: false,
    itemsLength: 0,
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['authority_initial_status_ordering'],
      sortDesc: [true],
    },
    detailsLoading: false,
    tableIds: [],
    filters: { ...defaultFilters },
  };
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        if (Array.isArray(source[key])) {
          source[key] = source[key].map((s, index) => ({ ...s, ...target[key][index] }));
        }
        Object.assign(target, { [key]: source[key] });
      }
    });
  }
  return mergeDeep(target, ...sources);
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.activationsCollection.forEach((activation) => {
        const index = state.tableIds.indexOf(activation.id);
        if (index !== -1) {
          tableItems[index] = activation;
        }
      });
      return tableItems;
    },
    activationHover: (state, getters, rootState) => state.activationsCollection.find(
      (activation) => activation.id === rootState.map.featureIdHovered.flight,
    ),
    activationSelected: (state, getters, rootState) => (
      state.activationsCollection.find(
        (activation) => activation.id === rootState.map.featureIdSelected.flight,
      )
    ),
    activeFilters: (state) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (key === 'dateFrom') {
          if (filters.dateFrom !== '') activeFilters += 1;
        } else if (key === 'dateTo') {
          if (filters.dateTo !== '') activeFilters += 1;
        } else if (filters[key] !== defaultFilters[key]) {
          activeFilters += 1;
        }
      });
      return activeFilters;
    },
    activationArea: (state, getters) => {
      const features = [];
      const { activationSelected } = getters;
      if (activationSelected) {
        const { status, approval } = activationSelected;
        approval.sub_approvals.forEach((subApproval) => {
          const { id, identifier, height, geometry } = subApproval;
          features.push({
            type: 'Feature',
            id,
            properties: {
              status,
              name: identifier,
              height: `${height} m`,
            },
            geometry,
          });
        });
      }
      return { type: 'FeatureCollection', features };
    },
    mapCenters: (state) => {
      const pointFeatures = state?.activationsCollection.map((activation) => {
        const { approval, status, date_start: dateStart, date_end: dateEnd } = activation;
        const { geometry } = turf.multiPolygon(
          approval.sub_approvals.map((s) => s?.geometry.coordinates),
        );
        return {
          type: 'Feature',
          id: activation.id,
          properties: {
            display_identifier: approval.display_identifier,
            date_start: dateStart,
            date_end: dateEnd,
            company_name: approval.company_name,
            status,
            max_flying_height: Math.max(...approval.sub_approvals.map((s) => s.height)),
          },
          geometry: turf.center(geometry).geometry,
        };
      });
      return { type: 'FeatureCollection', features: pointFeatures };
    },
    mapAreas: (state) => {
      const features = state.activationsCollection.map((activation) => {
        const { approval, status, date_start: dateStart, date_end: dateEnd } = activation;
        return {
          type: 'Feature',
          id: activation.id,
          properties: {
            display_identifier: approval.display_identifier,
            date_start: dateStart,
            date_end: dateEnd,
            company_name: approval.company_name,
            status,
          },
          geometry: turf.multiPolygon(
            approval.sub_approvals.map((s) => s?.geometry.coordinates),
          ).geometry,
        };
      });
      return { type: 'FeatureCollection', features };
    },
  },
  mutations: {
    [RESET_ACTIVATIONS](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [SET_ACTIVATIONS_LOADING](state, { loading }) {
      Vue.set(state, 'activationsLoading', loading);
    },
    [SET_ACTIVATIONS_COLLECTION_LOADED](state) {
      Vue.set(state, 'activationsLoaded', true);
    },
    [SET_ACTIVATIONS_COLLECTION](state, items = []) {
      Vue.set(state, 'activationsCollection', items);
      Vue.set(state, 'itemsLength', items.length);
    },
    [SET_ACTIVATION_DETAILS_LOADING](state) {
      Vue.set(state, 'activationDetailsLoading', true);
    },
    [SET_ACTIVATION_DETAILS](state, { id, data }) {
      const index = state.activationsCollection.findIndex((activation) => activation.id === id);
      if (index !== -1 && data.approval?.sub_approvals) {
        const { approval } = state.activationsCollection[index];
        const { sub_approvals: subApprovals } = approval;
        const { approval: updatedApproval } = data;
        for (let i = 0; i < updatedApproval.sub_approvals.length; i += 1) {
          const subApproval = subApprovals.find(
            (s) => s.id === updatedApproval.sub_approvals[i].id,
          );
          updatedApproval.sub_approvals[i].geometry = subApproval.geometry;
        }
      }
      Vue.set(state.activationsCollection, index, data);
      Vue.set(state, 'activationDetailsLoading', false);
    },
    [SET_FILTERS](state, payload) {
      Vue.set(state, 'filters', payload.filters);
    },
    [RESET_FILTERS](state) {
      Vue.set(state, 'filters', { ...defaultFilters, dateFrom: '', dateTo: '' });
    },
    [SET_TABLES_IDS](state, items) {
      Vue.set(state, 'tableIds', items.map((item) => item.id));
    },
    [SET_PAGINATION](state, { pagination }) {
      Vue.set(state, 'pagination', pagination);
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      if (page) Vue.set(state.pagination, 'page', page);
      if (itemsLength) Vue.set(state, 'itemsLength', itemsLength);
    },
    [FETCH_ACTIVATION_COLLECTION](state) {
      Vue.set(state, 'activationsCollection', []);
      Vue.set(state, 'activationsLoaded', false);
    },
    [SET_TABLE_ITEMS](state, { items = [] }) {
      items.forEach((item) => {
        const index = state.activationsCollection.findIndex(
          (activation) => activation.id === item.id,
        );
        const activation = state.activationsCollection[index];
        const properties = mergeDeep(item, activation);
        Vue.set(state.activationsCollection, index, properties);
      });
      Vue.set(state, 'tableIds', items.map((item) => item.id));
    },
  },
  actions: {
    async [GET_ACTIVATIONS]({ state, commit, dispatch }, { withId, reload = false }) {
      commit(SET_ACTIVATIONS_LOADING, { loading: true });
      const { itemsPerPage, page, sortDesc, sortBy } = state.pagination;
      const {
        status,
        dateFrom,
        dateTo,
        exploitantId,
        q,
        insideImpactingZone,
      } = state.filters;
      const getActivationsParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        geom: false,
        status,
        date_min: dateFrom,
        date_max: dateTo,
        exploitant_id: exploitantId,
        q,
        inside_impacting_zone: insideImpactingZone,
      };
      if (withId) getActivationsParams.with_id = withId;
      else getActivationsParams.offset = (page - 1) * itemsPerPage;
      if (reload || !state.activationsLoaded) await dispatch(GET_ACTIVATIONS_GEOMETRY);
      return APIService.getActivations(getActivationsParams)
        .then(({ data }) => {
          const { count, next, previous, results: items } = data;
          commit(SET_TABLE_ITEMS, { items });
          const newPage = getPageFromNextPreviousURL(next, previous, count, itemsPerPage);
          commit(UPDATE_PAGINATION, { itemsLength: count, page: newPage });
          commit(SET_ACTIVATIONS_LOADING, { loading: false });
          commit(SET_ACTIVATIONS_COLLECTION_LOADED);
        });
    },
    async [GET_ACTIVATIONS_GEOMETRY]({ state, commit }) {
      const {
        status,
        dateFrom,
        dateTo,
        exploitantId,
        q,
        insideImpactingZone,
      } = state.filters;
      const { sortBy, sortDesc } = state.pagination;
      const getActivationsParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        status,
        date_min: dateFrom,
        date_max: dateTo,
        exploitant_id: exploitantId,
        q,
        inside_impacting_zone: insideImpactingZone,
        geom: true,
        no_limit: true,
      };
      commit(FETCH_ACTIVATION_COLLECTION);
      await APIService.getActivations(getActivationsParams)
        .then(({ data }) => {
          const { results: items } = data;
          commit(SET_ACTIVATIONS_COLLECTION, items);
        });
    },
    async [GET_ALL_ACTIVATIONS]({ state, commit, dispatch }) {
      if (state.pagination.page === 1) await dispatch(GET_ACTIVATIONS, { reload: true });
      else {
        commit(UPDATE_PAGINATION, { itemsLength: state.itemsLength, page: 1 });
        await dispatch(GET_ACTIVATIONS_GEOMETRY);
      }
    },
    [SET_ACTIVATION_HOVER]({ dispatch }, activationId) {
      dispatch(
        SET_MAP_FEATURE_HOVERED_NS,
        { featureId: activationId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
    },
    async [SET_ACTIVATION_SELECTED]({ commit, getters, dispatch }, idSelected) {
      dispatch(
        SET_MAP_FEATURE_SELECTED_NS,
        { featureId: idSelected, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
      if (idSelected) {
        commit(SET_ACTIVATION_DETAILS_LOADING);
        if (getters.tableItems.findIndex((item) => item.id === idSelected) === -1) {
          await dispatch(GET_ACTIVATIONS, { withId: idSelected, reload: true })
            .then(async () => {
              await APIService.getActivationDetails(idSelected)
                .then(async ({ data }) => {
                  commit(SET_ACTIVATION_DETAILS, { id: idSelected, data });
                });
            });
        } else {
          await APIService.getActivationDetails(idSelected)
            .then(({ data }) => {
              commit(SET_ACTIVATION_DETAILS, { id: idSelected, data });
            });
        }
      }
    },
    async [SET_ACTIVATION_STATUS]({ commit }, { id, payload }) {
      await APIService.updateActivationStatus(id, payload)
        .then(({ data }) => {
          commit(SET_ACTIVATION_DETAILS, { id, data });
        });
    },
    [SET_FILTERS]({ commit }, filters) {
      commit(SET_FILTERS, { filters });
    },
    async [SET_PAGINATION]({ commit, dispatch }, pagination) {
      dispatch(SET_ACTIVATION_SELECTED, null);
      commit(SET_PAGINATION, { pagination });
      await dispatch(GET_ACTIVATIONS, { });
    },
    [RESET_ACTIVATIONS]({ commit }) {
      commit(RESET_ACTIVATIONS);
    },
    [RESET_FILTERS]({ commit }) {
      commit(RESET_FILTERS);
    },
  },
};
