<template>
  <v-expansion-panels
    v-model="panelExpanded"
    light
    flat
    class="dense__expansion-panel mt-2"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="dense__expansion-panel__header-content">
          <v-icon color="info">mdi-comment-text-outline</v-icon>
          <span class="ml-2">
            {{ texts.discussion }}
          </span>
          <span
            v-if="nbUserMessages"
            class="ml-1"
          >
            ({{ texts.numberMessage }})
          </span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-skeleton-loader
          v-if="loading"
          type="card"
        />
        <FlightChat
          v-else
          :flightId="flightId"
          :events="events"
          @messages-sent="(eventsUpdated) => events = eventsUpdated"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import APIService from '@/services/api';

import FlightChat from '@/components/Flights/FlightChat.vue';
import { UPDATE_FLIGHT_ID_DISCUSSION_OPEN_NS } from '@/store/flights';

export default {
  name: 'FlightDiscussion',
  components: { FlightChat },
  props: { flightId: Number },
  data() {
    return {
      loading: false,
      panelExpanded: undefined,
      events: [],
    };
  },
  computed: {
    texts() {
      return {
        discussion: this.$gettext('Internal discussion'),
        numberMessage: this.$gettextInterpolate(
          this.$ngettext(
            '%{ nb } message',
            '%{ nb } messages',
            this.nbUserMessages,
          ),
          { nb: this.nbUserMessages },
        ),
      };
    },
    nbUserMessages() {
      return this.events.filter((event) => event.is_user_event).length;
    },
    flightIdDiscussionOpen() {
      return this.$store.state.flights.flightIdDiscussionOpen;
    },
  },
  mounted() {
    if (this.flightIdDiscussionOpen) {
      if (this.flightIdDiscussionOpen === this.flightId) this.panelExpanded = 0;
      this.$store.dispatch(UPDATE_FLIGHT_ID_DISCUSSION_OPEN_NS);
    }
    this.getDiscussion();
  },
  methods: {
    getDiscussion() {
      this.loading = true;
      APIService.getFlightDiscussion(this.flightId)
        .then(({ data }) => {
          this.events = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
