import Vue from 'vue';

import APIService from '@/services/api';

import {
  AUTHORITY_TYPES,
  INTERFACE_VERSIONS,
  USER_TYPES,
  COOKIES_NAME,
  AUTHORITY_USER_ROLES,
  AUTHORITY_PRIVILEGES,
} from '@/settings';

import { RESET_DATA_STORES_NS, restoreHubspotChatBot } from '@/store/application';

const LOGIN_ROUTE = '/';

const namespace = 'authentication';

const FETCH_AUTHENT = 'FETCH_AUTHENT';
const LOGIN = 'LOGIN';
const LOGIN_SESAME = 'LOGIN_SESAME';
const LOGOUT = 'LOGOUT';
const RESET = 'RESET';
const SET_AUTHENT = 'SET_AUTHENT';
const ADD_OR_UPDATE_EXPLOITANT_CONNECTED = 'ADD_OR_UPDATE_EXPLOITANT_CONNECTED';
const CHANGE_EMAIL = 'CHANGE_EMAIL';
const CREATE_EMAIL_LIVESTREAM = 'CREATE_EMAIL_LIVESTREAM';
const DELETE_EMAIL_LIVESTREAM = 'DELETE_EMAIL_LIVESTREAM';
const CHANGE_SUPERVISER_EMAIL_PREFERENCES = 'CHANGE_SUPERVISER_EMAIL_PREFERENCES';
const CHANGE_SUPERVISER_BASEMAP_PREFERENCES = 'CHANGE_SUPERVISER_BASEMAP_PREFERENCES';
const STOP_REQUEST_APPROVAL_ALERTS = 'STOP_REQUEST_APPROVAL_ALERTS';
const STOP_DISPLAY_USE_MOBILE_APP_ALERT = 'STOP_DISPLAY_USE_MOBILE_APP_ALERT';
const SET_COMMERCIAL_NAME = 'SET_COMMERCIAL_NAME';
const GET_USER_SUB_EXPLOITANTS = 'GET_USER_SUB_EXPLOITANTS';
const GET_USER_SUB_EXPLOITANT_DETAILS = 'GET_USER_SUB_EXPLOITANT_DETAILS';
const SET_SELECTED_SUB_EXPLOITANT = 'SET_SELECTED_SUB_EXPLOITANT';
const SET_USER_SUB_EXPLOITANTS = 'SET_USER_SUB_EXPLOITANTS';
const SET_LOADING_USER_SUB_EXPLOITANTS = 'SET_LOADING_USER_SUB_EXPLOITANTS';
const UPDATE_DISPLAY_ONLY_MY_FLIGHTS = 'UPDATE_DISPLAY_ONLY_MY_FLIGHTS';
const HIJACK_USER = 'HIJACK_USER';
const SET_HIJACKED_USER = 'SET_HIJACKED_USER';
const SET_DEFAULT_FLYING_HEIGHT = 'SET_DEFAULT_FLYING_HEIGHT';
const CHANGE_DEFAULT_FLYING_HEIGHT = 'CHANGE_DEFAULT_FLYING_HEIGHT';
const SET_DEFAULT_FLYING_TIME = 'SET_DEFAULT_FLYING_TIME';
const CHANGE_DEFAULT_FLYING_TIME = 'CHANGE_DEFAULT_FLYING_TIME';
const SET_RECEIVES_NEWSLETTER = 'SET_RECEIVES_NEWSLETTER';
const CHANGE_RECEIVES_NEWSLETTER = 'CHANGE_RECEIVES_NEWSLETTER';
const SET_USER_DATA = 'SET_USER_DATA';
const REFRESH_USER_DATA = 'REFRESH_USER_DATA';
const SET_USER_REFRESHING = 'SET_USER_REFRESHING';
const RELEASE_HIJACKED_USER = 'RELEASE_HIJACKED_USER';
const SET_BEAMER_CONFIG = 'SET_BEAMER_CONFIG';
const SET_HUBSPOT_CONFIG = 'SET_HUBSPOT_CONFIG';
const SET_WEBSOCKET_TOKEN = 'SET_WEBSOCKET_TOKEN';
const FETCH_WEBSOCKET_AUTHENT = 'FETCH_WEBSOCKET_AUTHENT';
const GET_WEBSOCKET_AUTHENT = 'GET_WEBSOCKET_AUTHENT';
const SET_WEBSOCKET_TRIES = 'SET_WEBSOCKET_TRIES';
const SET_WEBSOCKET_LOG_SENT = 'SET_WEBSOCKET_LOG_SENT';
const RESET_WEBSOCKET_LOG = 'RESET_WEBSOCKET_LOG';
const SET_ACCEPTED_COOKIE = 'SET_ACCEPTED_COOKIE';
const SET_FILTER_APPROVALS_TO_PROCESS = 'SET_FILTER_APPROVALS_TO_PROCESS';

export const LOGIN_NS = `${namespace}/${LOGIN}`;
export const LOGIN_SESAME_NS = `${namespace}/${LOGIN_SESAME}`;
export const LOGOUT_NS = `${namespace}/${LOGOUT}`;
export const ADD_OR_UPDATE_EXPLOITANT_CONNECTED_NS = `${namespace}/${ADD_OR_UPDATE_EXPLOITANT_CONNECTED}`;
export const CHANGE_EMAIL_NS = `${namespace}/${CHANGE_EMAIL}`;
export const CREATE_EMAIL_LIVESTREAM_NS = `${namespace}/${CREATE_EMAIL_LIVESTREAM}`;
export const DELETE_EMAIL_LIVESTREAM_NS = `${namespace}/${DELETE_EMAIL_LIVESTREAM}`;
export const CHANGE_SUPERVISER_EMAIL_PREFERENCES_NS = `${namespace}/${CHANGE_SUPERVISER_EMAIL_PREFERENCES}`;
export const CHANGE_SUPERVISER_BASEMAP_PREFERENCES_NS = `${namespace}/${CHANGE_SUPERVISER_BASEMAP_PREFERENCES}`;
export const STOP_REQUEST_APPROVAL_ALERTS_NS = `${namespace}/${STOP_REQUEST_APPROVAL_ALERTS}`;
export const STOP_DISPLAY_USE_MOBILE_APP_ALERT_NS = `${namespace}/${STOP_DISPLAY_USE_MOBILE_APP_ALERT}`;
export const SET_COMMERCIAL_NAME_NS = `${namespace}/${SET_COMMERCIAL_NAME}`;
export const GET_USER_SUB_EXPLOITANTS_NS = `${namespace}/${GET_USER_SUB_EXPLOITANTS}`;
export const GET_USER_SUB_EXPLOITANT_DETAILS_NS = `${namespace}/${GET_USER_SUB_EXPLOITANT_DETAILS}`;
export const SET_SELECTED_SUB_EXPLOITANT_NS = `${namespace}/${SET_SELECTED_SUB_EXPLOITANT}`;
export const UPDATE_DISPLAY_ONLY_MY_FLIGHTS_NS = `${namespace}/${UPDATE_DISPLAY_ONLY_MY_FLIGHTS}`;
export const HIJACK_USER_NS = `${namespace}/${HIJACK_USER}`;
export const CHANGE_DEFAULT_FLYING_HEIGHT_NS = `${namespace}/${CHANGE_DEFAULT_FLYING_HEIGHT}`;
export const CHANGE_DEFAULT_FLYING_TIME_NS = `${namespace}/${CHANGE_DEFAULT_FLYING_TIME}`;
export const CHANGE_RECEIVES_NEWSLETTER_NS = `${namespace}/${CHANGE_RECEIVES_NEWSLETTER}`;
export const REFRESH_USER_DATA_NS = `${namespace}/${REFRESH_USER_DATA}`;
export const RELEASE_HIJACKED_USER_NS = `${namespace}/${RELEASE_HIJACKED_USER}`;
export const SET_BEAMER_CONFIG_NS = `${namespace}/${SET_BEAMER_CONFIG}`;
export const SET_HUBSPOT_CONFIG_NS = `${namespace}/${SET_HUBSPOT_CONFIG}`;
export const GET_WEBSOCKET_AUTHENT_NS = `${namespace}/${GET_WEBSOCKET_AUTHENT}`;
export const RESET_WEBSOCKET_LOG_NS = `${namespace}/${RESET_WEBSOCKET_LOG}`;
export const SET_ACCEPTED_COOKIE_NS = `${namespace}/${SET_ACCEPTED_COOKIE}`;
export const MAX_RETRY_WEBSOCKET_CONNEXION = 5;

function initialState() {
  return {
    logged: false,
    loadingAuthentication: false,
    loadingWebSocketAuthentication: false,
    errorMessage: '',
    user: null,
    userSubExploitantsLoading: false,
    userSubExploitants: [],
    selectedSubExploitant: null,
    token: '',
    websocketToken: '',
    websocketTries: 0,
    websocketErrorSent: false,
    availableMaps: [],
    hijackedUserId: null,
    hijackedUserName: '',
    userRefreshing: false,
    loginData: {},
    beamerEmbedScriptLink: 'https://app.getbeamer.com/js/beamer-embed.js',
    beamerConfig: {
      product_id: 'hDJEkNlp31747',
      selector: 'beamerButton',
      top: -10,
      left: 20,
      multi_user: true,
    },
    acceptedCookies: [],
    exploitantsConnected: {},
    showOnlyApprovalsToProcess: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    isUserAuthority: (state) => state.user?.user_type === USER_TYPES.AUTHORITIES,
    isAuthorityUSSP: (state) => state.user?.user_type === USER_TYPES.AUTHORITY_USSPS,
    isDronistUSSP: (state) => state.user?.user_type === USER_TYPES.DRONIST_USSPS,
    isUserDronist: (state) => (
      state.user
      && (state.user.user_type === USER_TYPES.EXPLOITANTS
        || state.user.user_type === USER_TYPES.SUBSCRIBERS)
    ),
    isUserPrefecture: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state?.user.authority_type === AUTHORITY_TYPES.PREFECTURE
    ),
    isUserVisitor: (state) => state.user?.user_type === USER_TYPES.VISITORS,
    isUserSuperviserUssp: (state) => (
      state.user?.user_type === USER_TYPES.REALTIME_SUPERVISER_USSPS
    ),
    userLongName: (state) => state.user?.long_name,
    userContactName: (state) => state.user?.contact_full_name,
    isUserExploitantAdmin: (state, getters) => (
      getters.isUserDronist && state.user.is_exploitant_admin
    ),
    isUserExploitantMainContact: (state, getters) => (
      getters.isUserDronist && state.user.is_exploitant_main_contact
    ),
    isUserExploitantManager: (state, getters) => (
      getters.isUserDronist && state.user.is_sub_exploitant_manager
    ),
    hasManySubExploitants: (state) => state.userSubExploitants.length > 1,
    isAuthorityAdmin: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.role === AUTHORITY_USER_ROLES.ADMIN
    ),
    isAuthorityManager: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.role === AUTHORITY_USER_ROLES.MANAGER
    ),
    isAuthorityController: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.role === AUTHORITY_USER_ROLES.CONTROLLER
    ),
    isAuthorityAdminOrManager: (state, getters) => (
      getters.isAuthorityAdmin || getters.isAuthorityManager
    ),
    authorityHasApprovalsPrivileges: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.access_privileges?.includes(AUTHORITY_PRIVILEGES.APPROVALS)
    ),
    authorityHasActivationsPrivileges: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.access_privileges?.includes(AUTHORITY_PRIVILEGES.ACTIVATIONS)
    ),
    showOnlyApprovalsToProcess: (state) => state.showOnlyApprovalsToProcess,
    hasSimplifiedInterface: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user?.superviser?.simplified_interface
    ),
    // User can display trackers on the map
    trackingActivated: (state) => state.user?.tracking_activated,
    // User has non active subscription (Free) or is still in trial period
    userNotSubscribed: (state) => state.user && !state.user.subscribed,
    // User has active subscription or is in trial period
    isSubscriptionActive: (state) => state.user?.subscription
    && state.user.subscription.active,
    isUserEnterprise: (state) => state.user?.subscription
      && state.user.subscription.enterprise,
    hssNotificationsAllowed: (state) => state.user?.hss_notifications_allowed,
    isAuthorityFullScreenInterface: (state) => (
      state.user?.user_type === USER_TYPES.AUTHORITIES
      && state.user.authority_interface_version === INTERFACE_VERSIONS.FULL_SCREEN
    ),
    axeptioActivated: () => (process.env.VUE_APP_AXEPTIO_ACTIVATED === '1'),
    hasAcceptedCookieStripe: (state) => state.user
      && state.acceptedCookies.includes(COOKIES_NAME.STRIPE),
    hasAcceptedCookieBeamer: (state) => state.user
      && state.acceptedCookies.includes(COOKIES_NAME.BEAMER),
    isAdminOrSpecialistPilot: (state) => (
      state.user?.user_type === 'admin_privileges'
      || state.user?.contact_profile === 'standard'
    ),
    isGeneralistPilot: (state) => state.user?.contact_profile === 'rte_generalist',
    isGeneralistManager: (state, getters, rootState, rootGetters) => (
      getters.isUserExploitantManager
      && rootGetters['exploitants/openFlightSimplifiedFormForManager']
    ),
    isGeneralistPilotOrManager: (state, getters) => (
      getters.isGeneralistPilot || getters.isGeneralistManager
    ),
    mailjet_newsletter_subscription_activated:
      (state) => state.user?.mailjet_newsletter_subscription_activated,
  },
  mutations: {
    [RESET](state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        Vue.set(state, key, s[key]);
      });
    },
    [FETCH_AUTHENT](state) {
      Vue.set(state, 'loadingAuthentication', true);
    },
    [FETCH_WEBSOCKET_AUTHENT](state) {
      Vue.set(state, 'loadingWebSocketAuthentication', true);
    },
    [SET_WEBSOCKET_TOKEN](state, websocketToken) {
      Vue.set(state, 'websocketToken', websocketToken);
      Vue.set(state, 'loadingAuthentication', false);
    },
    [SET_AUTHENT](state, { data = {}, errorMessage = '', logged = false, storeLoginData = true }) {
      Vue.set(state, 'loadingAuthentication', false);
      Vue.set(state, 'user', data.user);
      Vue.set(state, 'userSubExploitants', []);
      Vue.set(state, 'selectedSubExploitant', null);
      data.user && Vue.set(state, 'availableMaps', data.user.available_maps);
      Vue.set(state, 'token', data.token);
      Vue.set(state, 'errorMessage', errorMessage);
      Vue.set(state, 'logged', logged);
      if (storeLoginData) {
        Vue.set(state, 'loginData', data);
      }
    },
    [ADD_OR_UPDATE_EXPLOITANT_CONNECTED](state, exploitantId) {
      const { exploitantsConnected } = state;
      exploitantsConnected[exploitantId] = Date.now();
      Vue.set(state, 'exploitantsConnected', exploitantsConnected);
    },
    [SET_USER_DATA](state, data) {
      Vue.set(state, 'user', data);
    },
    [SET_USER_REFRESHING](state, value) {
      Vue.set(state, 'userRefreshing', value);
    },
    [SET_DEFAULT_FLYING_HEIGHT](state, value) {
      Vue.set(state.user, 'default_flying_height', value);
    },
    [SET_DEFAULT_FLYING_TIME](state, value) {
      Vue.set(state.user, 'default_flying_time', value);
    },
    [SET_RECEIVES_NEWSLETTER](state, value) {
      Vue.set(state.user, 'contact_subscribed_to_newsletter', value);
    },
    [SET_COMMERCIAL_NAME](state, name) {
      Vue.set(state.user, 'commercial_name', name);
    },
    [CHANGE_EMAIL](state, email) {
      Vue.set(state.user, 'email', email.toLowerCase());
    },
    [SET_SELECTED_SUB_EXPLOITANT](state, subExploitantId) {
      Vue.set(state, 'selectedSubExploitant', subExploitantId);
    },
    [SET_USER_SUB_EXPLOITANTS](state, userSubExploitants) {
      Vue.set(state, 'userSubExploitants', userSubExploitants);
      Vue.set(state, 'userSubExploitantsLoading', false);
    },
    [SET_FILTER_APPROVALS_TO_PROCESS](state, { showOnlyApprovalsToProcess }) {
      Vue.set(state, 'showOnlyApprovalsToProcess', showOnlyApprovalsToProcess);
    },
    [SET_LOADING_USER_SUB_EXPLOITANTS](state, value) {
      Vue.set(state, 'userSubExploitantsLoading', value);
    },
    [GET_USER_SUB_EXPLOITANT_DETAILS](state, { subExploitantId, pilots = [], drones = [] }) {
      const { userSubExploitants } = state;
      const index = userSubExploitants.findIndex((s) => s.id === subExploitantId);
      if (index !== -1) {
        if (pilots.length) {
          Vue.set(state.userSubExploitants[index], 'pilots', pilots);
        }
        if (drones.length) {
          Vue.set(state.userSubExploitants[index], 'drones', drones);
        }
      }
    },
    [CREATE_EMAIL_LIVESTREAM](state, livestreamEmail) {
      const livestreamEmails = state.user.livestream_emails;
      livestreamEmails.push(livestreamEmail);
      Vue.set(state.user, 'livestream_emails', livestreamEmails);
    },
    [DELETE_EMAIL_LIVESTREAM](state, livestreamEmailId) {
      const livestreamEmails = state.user.livestream_emails;
      const index = livestreamEmails.findIndex(
        (livestreamEmail) => livestreamEmail.id === livestreamEmailId,
      );
      livestreamEmails.splice(index, 1);
      Vue.set(state.user, 'livestream_emails', livestreamEmails);
    },
    [CHANGE_SUPERVISER_EMAIL_PREFERENCES](state, superviserEmailPreferences) {
      state.user.superviser.email_notification_preferences = superviserEmailPreferences;
    },
    [CHANGE_SUPERVISER_BASEMAP_PREFERENCES](state, superviserBasemapPreferences) {
      state.user.superviser.preferred_basemap = superviserBasemapPreferences;
    },
    [STOP_REQUEST_APPROVAL_ALERTS](state) {
      Vue.set(state.user, 'display_request_approval_alert', false);
    },
    [STOP_DISPLAY_USE_MOBILE_APP_ALERT](state) {
      Vue.set(state.user, 'display_use_mobile_app_alert', false);
    },
    [UPDATE_DISPLAY_ONLY_MY_FLIGHTS](state, value) {
      Vue.set(state.user, 'display_only_my_flights', value);
    },
    [SET_HIJACKED_USER](state, { userId, userName }) {
      Vue.set(state, 'hijackedUserId', userId);
      Vue.set(state, 'hijackedUserName', userName);
    },
    [SET_WEBSOCKET_TRIES](state, value) {
      Vue.set(state, 'websocketTries', value);
    },
    [SET_WEBSOCKET_LOG_SENT](state, value) {
      Vue.set(state, 'websocketErrorSent', value);
    },
    [SET_ACCEPTED_COOKIE](state, { cookieName, value }) {
      let { acceptedCookies } = state;
      if (value) {
        if (!acceptedCookies.includes(cookieName)) {
          acceptedCookies.push(cookieName);
        }
      } else {
        acceptedCookies = acceptedCookies.filter((v) => v !== cookieName);
      }
      Vue.set(state, 'acceptedCookies', acceptedCookies);
    },
  },
  actions: {
    async [LOGIN]({ commit }, { username, password }) {
      commit(FETCH_AUTHENT);
      try {
        const { data } = await APIService.login(username, password) || {};
        commit(SET_AUTHENT, {
          data,
          logged: true,
        });
      } catch (error) {
        let errorMessage = '';
        const { response = {}, message } = error;
        if (response.data && response.data.non_field_errors) {
          errorMessage += response.data.non_field_errors[0];
        }
        errorMessage += message;
        errorMessage = errorMessage || 'Une erreur est survenue';
        commit(SET_AUTHENT, { errorMessage });
      }
    },
    [LOGOUT]({ commit, dispatch, state }) {
      const { logged } = state;
      commit(RESET);
      dispatch(RESET_DATA_STORES_NS, null, { root: true });
      commit(SET_HIJACKED_USER, { userId: null, userName: '' });
      if (logged) {
        window.location = LOGIN_ROUTE;
      }
    },
    async [LOGIN_SESAME]({ commit }, { sesame }) {
      commit(FETCH_AUTHENT);
      try {
        const { data } = await APIService.loginToken({ sesame }) || {};
        commit(SET_AUTHENT, {
          data,
          logged: true,
        });
      } catch (error) {
        let errorMessage = '';
        const { response = {}, message } = error;
        if (response.data && response.data.non_field_errors) {
          errorMessage += response.data.non_field_errors[0];
        }
        errorMessage += message;
        errorMessage = errorMessage || 'Une erreur est survenue';
        commit(SET_AUTHENT, { errorMessage });
      }
    },
    [RESET_WEBSOCKET_LOG]({ commit }) {
      commit(SET_WEBSOCKET_TRIES, (0));
      commit(SET_WEBSOCKET_LOG_SENT, (false));
    },
    async [GET_WEBSOCKET_AUTHENT]({ commit, state }) {
      const numberTry = state.websocketTries + 1;
      commit(SET_WEBSOCKET_TRIES, (numberTry));
      if (numberTry < MAX_RETRY_WEBSOCKET_CONNEXION) {
        commit(FETCH_WEBSOCKET_AUTHENT);
        try {
          // eslint-disable-next-line no-console
          console.log('Retrieving websocket token...');
          const response = await APIService.getWebsocketToken() || {};
          const websocketToken = response.data;
          commit(SET_WEBSOCKET_TOKEN, websocketToken);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error trying to fetch the websocket token.');
          // eslint-disable-next-line no-console
          console.log(error);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('Maximum number of websocket connexion exceed.');
        if (!state.websocketErrorSent) {
          commit(SET_WEBSOCKET_LOG_SENT, (true));
          APIService.sendWebsocketLog(false);
        }
      }
    },
    [RELEASE_HIJACKED_USER]({ commit, state }) {
      commit(SET_HIJACKED_USER, { userId: null, userName: '' });
      commit(SET_AUTHENT, {
        data: state.loginData,
        logged: true,
        storeLoginData: true,
      });
    },
    [CHANGE_EMAIL]({ commit }, email) {
      commit(CHANGE_EMAIL, email);
    },
    [CREATE_EMAIL_LIVESTREAM]({ commit }, livestreamEmail) {
      commit(CREATE_EMAIL_LIVESTREAM, livestreamEmail);
    },
    [DELETE_EMAIL_LIVESTREAM]({ commit }, livestreamEmailId) {
      commit(DELETE_EMAIL_LIVESTREAM, livestreamEmailId);
    },
    [CHANGE_SUPERVISER_EMAIL_PREFERENCES]({ commit }, email) {
      commit(CHANGE_SUPERVISER_EMAIL_PREFERENCES, email);
    },
    [CHANGE_SUPERVISER_BASEMAP_PREFERENCES]({ commit }, basemap) {
      commit(CHANGE_SUPERVISER_BASEMAP_PREFERENCES, basemap);
    },
    [SET_COMMERCIAL_NAME]({ commit }, { name }) {
      commit(SET_COMMERCIAL_NAME, name);
    },
    [CHANGE_DEFAULT_FLYING_HEIGHT]({ commit }, value) {
      commit(SET_DEFAULT_FLYING_HEIGHT, value);
    },
    [CHANGE_DEFAULT_FLYING_TIME]({ commit }, value) {
      commit(SET_DEFAULT_FLYING_TIME, value);
    },
    [CHANGE_RECEIVES_NEWSLETTER]({ commit }, value) {
      commit(SET_RECEIVES_NEWSLETTER, value);
    },
    async [UPDATE_DISPLAY_ONLY_MY_FLIGHTS]({ state, commit }, value) {
      const promise = APIService.updateContactSettings(
        state.user.contact_id,
        { display_only_my_flights: value },
      );
      commit(UPDATE_DISPLAY_ONLY_MY_FLIGHTS, value);
      return promise;
    },
    async [HIJACK_USER]({ commit, dispatch }, { userId }) {
      commit(FETCH_AUTHENT);
      try {
        const { data } = await APIService.hijackUser({ userId });
        commit(SET_AUTHENT, {
          data,
          logged: true,
          storeLoginData: false,
        });
        const userName = data.user.username;
        commit(SET_HIJACKED_USER, { userId, userName });
        dispatch(GET_WEBSOCKET_AUTHENT);
      } catch (error) {
        let errorMessage = '';
        const { response = {}, message } = error;
        if (response.data && response.data.non_field_errors) {
          errorMessage += response.data.non_field_errors[0];
        }
        errorMessage += message;
        errorMessage = errorMessage || 'Une erreur est survenue';
        commit(SET_AUTHENT, { errorMessage });
      }
    },
    async [REFRESH_USER_DATA]({ state, commit }) {
      commit(SET_USER_REFRESHING, true);
      const { data } = await APIService.refreshUserData(state.user.id);
      commit(SET_USER_DATA, data);
      commit(SET_USER_REFRESHING, false);
    },
    [GET_USER_SUB_EXPLOITANTS]({ commit }) {
      commit(SET_LOADING_USER_SUB_EXPLOITANTS, true);
      APIService.getUserSubExploitants()
        .then(({ data }) => {
          commit(SET_USER_SUB_EXPLOITANTS, data);
        });
    },
    [SET_BEAMER_CONFIG]({ state, getters }) {
      if (getters.isGeneralistPilotOrManager) return;
      let userSuffix;
      if (APIService._instance.defaults.baseURL.includes('fly.clearance.aero')) {
        userSuffix = '';
      } else {
        userSuffix = ' - dev';
      }
      let filter = '';
      if (getters.isUserAuthority) {
        filter = 'authorities';
      } else if (getters.isUserDronist) {
        filter = 'exploitants';
      }
      const beamerConfigScript = document.createElement('script');
      beamerConfigScript.text = `
        var beamer_config = {
          product_id : "${state.beamerConfig.product_id}",
          selector : "${state.beamerConfig.selector}",
          top: "${state.beamerConfig.top}",
          left: "${state.beamerConfig.left}",
          filter: "${filter}",
          user_id: "${state.user.id}${userSuffix}",
          user_email: "${state.user.email}${userSuffix}",
          user_firstname: "${state.user.long_name}",
          user_lastname: "${state.user.contact_full_name}${userSuffix}",
          multi_user: "${state.beamerConfig.multi_user}",
        };
      `;
      document.body.appendChild(beamerConfigScript);
      const beamerScript = document.createElement('script');
      beamerScript.setAttribute('src', state.beamerEmbedScriptLink);
      beamerScript.setAttribute('defer', 'defer');
      document.body.appendChild(beamerScript);
    },
    [SET_HUBSPOT_CONFIG]({ getters }) {
      if (getters.isGeneralistPilotOrManager) return;
      if (document.getElementById('hs-script-loader')) {
        restoreHubspotChatBot();
      } else {
        const el = document.createElement('script');
        el.setAttribute('id', 'hs-script-loader');
        el.setAttribute('type', 'text/javascript');
        el.setAttribute('async', true);
        el.setAttribute('defer', true);
        el.setAttribute('src', '//js.hs-scripts.com/5025618.js');
        document.body.append(el);
      }
    },
    [SET_ACCEPTED_COOKIE]({ commit }, { cookieName, value }) {
      commit(SET_ACCEPTED_COOKIE, { cookieName, value });
    },
    [STOP_DISPLAY_USE_MOBILE_APP_ALERT]({ state, commit }) {
      APIService.updateContactSettings(
        state.user.contact_id,
        { display_use_mobile_app_alert: false },
      );
      commit(STOP_DISPLAY_USE_MOBILE_APP_ALERT);
    },
  },
};
