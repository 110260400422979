<template>
  <v-row no-gutters>
    <v-card class="default--dialog__card">
      <v-skeleton-loader
        :loading="dialogInitLoading"
        type="card-heading, list-item, list-item-two-line,
        date-picker-days, list-item-three-line, actions"
      >
        <v-card-title>
          <v-icon class="primary--text">icon-archive</v-icon>
          <span class="px-2">
            <template v-if="isSingleFlight">
              <translate>Archive mission</translate>
              <span>
                {{ title }}
              </span>
            </template>
            <template v-else>
              <translate>Archive selected missions</translate>
            </template>
          </span>
          <v-icon @click="close()">
            close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-if="!isSingleFlight"
            text
            dense
            type="info"
            icon="mdi-information-outline"
          >
            <translate :translate-params="{number: flightsToClose.length}">
              The following parameters will be applied to %{number} missions.
            </translate>
          </v-alert>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div class="px-1">
              <!-- Forbidden flight -->
              <v-checkbox
                v-if="canForbidFlights"
                v-model="forbiddenFlights"
                :label="labels.forbiddenFlights"
                class="mt-1 mb-3"
                hide-details
              />
              <!-- Cancelled flight -->
              <v-checkbox
                v-model="canceledFlights"
                :label="labels.canceledFlights"
                :disabled="forbiddenFlights"
                class="mt-1 mb-3"
                hide-details
              />
              <!-- Pilots -->
              <v-autocomplete
                class="section-header section-header--select"
                v-model="pilotIds"
                :items="pilots"
                multiple
                item-text="full_name"
                item-value="id"
                :no-data-text="$gettext('No pilot found')"
                :placeholder="placeholders.pilots"
                :loading="pilotsLoading"
                :disabled="canceledFlights"
                prepend-icon="icon-autority_line"
                single-line
              />
              <!-- Section Scenario -->
              <template v-if="!isGeneralistPilotOrManager">
                <v-select
                  class="section-header section-header--select"
                  v-model="scenario"
                  :items="scenarios"
                  :loading="loadingDetails"
                  :placeholder="placeholders.scenario"
                  single-line
                  prepend-icon="mdi-clipboard-text-outline"
                  :disabled="canceledFlights"
                  :messages="texts.scenarioExplainText"
                >
                  <template
                    slot="message"
                    slot-scope="{ message }"
                  >
                    <span v-html="message" />
                  </template>
                </v-select>
              </template>
              <!-- Activity domain -->
              <v-select
                class="section-header section-header--select"
                v-model="activityDomain"
                :items="activityDomainItems"
                item-text="text"
                item-value="key"
                single-line
                prepend-icon="mdi-factory"
                :placeholder="placeholders.activityDomain"
                :loading="metaLoading"
                :rules="rules.required"
                :disabled="canceledFlights"
              />
              <!-- Comment -->
              <v-textarea
                v-model="comment"
                :placeholder="placeholders.comment"
                :disabled="canceledFlights && !forbiddenFlights"
                :loading="loadingDetails"
                :rules="forbiddenFlights ? rules.required : []"
                rows="2"
                prepend-icon="mdi-comment-text-outline"
                class="section-header section-header--select"
              />
            </div>
            <!-- Flight Dates and duration -->
            <v-row>
              <v-col
                cols="12"
                sm="7"
              >
                <v-date-picker
                  v-model="dates"
                  no-title
                  full-width
                  class="elevation-0"
                  range
                  locale="fr"
                  first-day-of-week="1"
                  :max="formatDate(today)"
                  :disabled="canceledFlights"
                />
              </v-col>
              <v-col
                cols="12"
                sm="5"
                :style="[canceledFlights ? {'color': 'grey' } : '']"
              >
                <!-- Flight Period -->
                <v-row
                  no-gutters
                  class="section-header pa-3"
                >
                  <v-col cols="12">
                    <div class="mb-2">
                      <v-icon>mdi-calendar</v-icon>
                      <translate class="section-header__text">Flight period</translate>
                    </div>
                    <div class="px-1">
                      <div>
                        <translate>Du</translate>
                        <span class="ml-2">
                          {{ dateStart | date }}
                        </span>
                      </div>
                      <div>
                        <translate>Au</translate>
                        <span class="ml-2">
                          {{ dateEnd | date }}
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- Flight Duration -->
                <v-row
                  no-gutters
                  class="section-header pt-3 px-3"
                  v-if="!isGeneralistPilotOrManager"
                >
                  <v-col cols="12">
                    <div class="mb-2">
                      <v-icon>mdi-timelapse</v-icon>
                      <translate class="section-header__text">Flight Duration</translate>
                    </div>
                    <TimePicker
                      :time="duration"
                      :rules="rules.required"
                      :disabled="canceledFlights"
                      appendIcon=""
                      @update:time="duration = $event"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Cancellation Alert (notification, approval request)  -->
            <div>
              <v-alert
                text
                dense
                type="info"
                icon="mdi-information-outline"
                class="mt-2 mb-0"
              >
                {{ texts.cancellationInformationAlert }}
              </v-alert>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 px-0 mx-4 mb-5 mb-sm-2">
          <v-btn
            class="archive-button"
            color="primary"
            :disabled="!valid"
            @click.native="agree()"
            :loading="closeFlightsLoading"
            block
          >
            <translate>Archive</translate>
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-row>
</template>

<script>
import { format } from 'date-fns';

import API from '@/services/api';

import TimePicker from '@/components/TimePicker.vue';

import { SCENARIO_ARTICLE_LINK } from '@/settings';

import {
  GET_ALL_FLIGHTS_NS,
  GET_FLIGHT_META_NS,
  SET_FLIGHT_SELECTED_NS,
} from '@/store/flights';
import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';
import { CANCELLED } from '@/store/status';

export default {
  name: 'FlightCloseForm',
  components: { TimePicker },
  props: {
    flights: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogInitLoading: false,
      closeFlightsLoading: false,
      flightsToClose: [],
      forbiddenFlights: false,
      canceledFlights: false,
      pilotIds: [],
      scenario: null,
      activityDomain: null,
      comment: null,
      dates: [],
      dateStart: null,
      dateEnd: null,
      duration: '',
      timepickerDialog: false,
      loadingDetails: false,
      scenarios: [
        ...this.$store.getters['authentication/isUserEnterprise']
          ? [{ value: 's0', text: this.$gettext('Scenario S0') }] : [],
        { value: 's1', text: this.$gettext('Scenario S1') },
        { value: 's2', text: this.$gettext('Scenario S2') },
        { value: 's3', text: this.$gettext('Scenario S3') },
        { value: 'a1', text: this.$gettext('Open category A1') },
        { value: 'a2', text: this.$gettext('Open category A2') },
        { value: 'a3', text: this.$gettext('Open category A3') },
        { value: 'sts01', text: this.$gettext('Scenario STS-01') },
        { value: 'sts02', text: this.$gettext('Scenario STS-02') },
        { value: 'hss', text: this.$gettext('Non-standard scenarios (e.g. LUC)') },
      ],
      rules: {
        required: [
          (value) => !!value || this.$gettext('A value is required'),
        ],
      },
      valid: true,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    texts() {
      return {
        cancellationInformationAlert: this.$ngettext(
          // singular
          'The notifications and approvals linked to this flight will be cancelled',
          // plural
          'The notifications and approvals linked to these flights will be cancelled',
          this.flightsToClose.length,
        ),
        scenarioExplainText: this.$gettextInterpolate(
          this.$gettext(
            `<a href="%{ link }" class="text--secondary" target="_blank"
            >More informations on flight's scenarios</a>`,
          ),
          { link: SCENARIO_ARTICLE_LINK },
        ),
      };
    },
    labels() {
      return {
        forbiddenFlights: this.$ngettext(
          // singular
          'Forbid the flight',
          // plural
          'Forbid flights',
          this.flightsToClose.length,
        ),
        canceledFlights: this.$ngettext(
          // singular
          'The flight was not conducted',
          // plural
          'Flights were not conducted',
          this.flightsToClose.length,
        ),
      };
    },
    placeholders() {
      return {
        pilots: this.$gettext('Pilots'),
        scenario: this.$gettext('Scenario'),
        activityDomain: this.$gettext('Activity Domain'),
        comment: this.$gettext('Comment'),
      };
    },
    isSingleFlight() {
      return this.flightsToClose.length === 1;
    },
    singleFlight() {
      return this.isSingleFlight && this.flightsToClose[0];
    },
    isStandardMission() {
      return (this.singleFlight.mission_type === 'standard');
    },
    detailsLoaded() {
      return this.$store.getters['flights/flightSelected'] !== undefined;
    },
    title() {
      return this.isSingleFlight ? this.singleFlight.name : '';
    },
    pilotsLoading() {
      return this.$store.state.pilots.loadingPilots || this.loadingDetails;
    },
    pilots() {
      return this.$store.getters['pilots/activePilots'];
    },
    metaLoading() {
      return !this.$store.state.flights.meta.isFlightMetaLoaded || this.loadingDetails;
    },
    defaultActivityDomain() {
      return this.$store.state.flights.meta.default;
    },
    activityDomainItems() {
      return this.$store.state.flights.meta.activityDomains;
    },
    today() {
      const date = new Date();
      const offsetMs = date.getTimezoneOffset() * 60 * 1000;
      const msLocal = date.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      return dateLocal;
    },
    isGeneralistPilotOrManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    isUserExploitantAdmin() {
      return this.$store.getters['authentication/isUserExploitantAdmin'];
    },
    customConfig() {
      return this.$store.state.exploitants.exploitationDetails?.custom_config || {};
    },
    canForbidFlights() {
      return this.isUserExploitantAdmin && this.customConfig.can_forbid_flights;
    },
  },
  watch: {
    defaultActivityDomain(newValue) {
      this.activityDomain = newValue.key;
    },
    flights(newValue, oldValue) {
      this.flightsToClose = newValue;
      if (
        (oldValue.length !== 1 && newValue.length === 1)
        || (
          newValue.length === 1
          && oldValue.length === 1
          && (newValue[0].id !== oldValue[0].id)
        )
      ) {
        this.loadingDetails = true;
        API.getFlightDetails(this.flights[0].id, { no_geometry: true })
          .then(({ data }) => {
            this.flightsToClose = [data];
            this.setArchiveDetails();
          })
          .finally(() => {
            this.loadingDetails = false;
          });
      } else if (!this.isSingleFlight) {
        this.setArchiveDetails();
      }
    },
    dates([newDateStart, newDateEnd]) {
      this.dateStart = newDateStart;
      this.dateEnd = newDateEnd || newDateStart;
      if (this.dateStart > this.dateEnd) {
        [this.dateStart, this.dateEnd] = [this.dateEnd, this.dateStart];
      }
    },
    canceledFlights(newValue) {
      if (newValue === true) {
        this.duration = '00:00:00';
        if (!this.activityDomain) {
          this.activityDomain = 'other';
        }
      }
    },
    forbiddenFlights(newValue) {
      if (newValue) this.canceledFlights = true;
    },
  },
  async created() {
    this.dialogInitLoading = true;
    this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS);
    if (this.flights.length === 1 && !this.detailsLoaded) {
      this.loadingDetails = true;
      API.getFlightDetails(this.flights[0].id, { no_geometry: true })
        .then(({ data }) => {
          this.flightsToClose = [data];
          this.setArchiveDetails();
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    } else {
      this.flightsToClose = this.flights;
      this.setArchiveDetails();
    }
    this.dialogInitLoading = false;
  },
  methods: {
    prepareFlightsToUpdate() {
      return this.flightsToClose.map((flight) => {
        const payload = {
          id: flight.id,
          pilot_ids: this.pilotIds,
          expected_duration: this.duration,
          activity_domain: this.activityDomain,
          declared_scenario: this.scenario,
          comment: this.comment,
          date_start: this.dateStart,
          date_end: this.dateEnd,
          canceled_flight: this.canceledFlights,
        };
        if (this.canForbidFlights) payload.forbidden_flight = this.forbiddenFlights;

        return payload;
      });
    },
    formatDate(date) {
      return format(date, 'yyyy-MM-dd');
    },
    getDates() {
      let dateStart = null;
      let dateEnd = null;
      if (this.isSingleFlight) {
        if (this.singleFlight.effective_date_start && this.singleFlight.effective_date_end) {
          dateStart = new Date(this.singleFlight.effective_date_start);
          dateEnd = new Date(this.singleFlight.effective_date_end);
        } else {
          dateStart = new Date(this.singleFlight.date_start);
          dateEnd = new Date(this.singleFlight.date_end);
        }
      }
      return { dateStart, dateEnd };
    },
    getDuration() {
      if (this.isSingleFlight) {
        return this.singleFlight.effective_duration || this.singleFlight.expected_duration;
      }
      return '';
    },
    getScenario() {
      if (this.isSingleFlight && !this.isStandardMission && !this.singleFlight.is_archived) {
        return ('hss');
      }
      return (
        this.isSingleFlight
          ? (this.singleFlight.declared_scenario || this.singleFlight.scenario) || ''
          : ''
      ).toLowerCase();
    },
    getPilots() {
      return this.isSingleFlight ? this.singleFlight.pilots.map((p) => p.id) : [];
    },
    agree() {
      if (this.$refs.form.validate()) {
        this.closeFlightsLoading = true;
        const payload = this.prepareFlightsToUpdate();
        API.classifyFlights(payload)
          .then(() => {
            let message = '';
            if (this.isSingleFlight) {
              message = this.$gettextInterpolate(
                this.$gettext('Flight n°%{ flightId } archived'),
                { flightId: this.singleFlight.id },
              );
            } else {
              message = this.$gettext('Flights archived');
            }
            this.showMessage(message, 'success');
            this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: null, reload: true });
            this.$store.dispatch(GET_ALL_FLIGHTS_NS, { updateBoundingBox: false });
          })
          .finally(() => {
            this.closeFlightsLoading = false;
            this.close();
          });
      }
    },
    close() {
      this.$emit('close');
      this.flightsToClose = [];
      this.forbiddenFlights = false;
      this.canceledFlights = false;
      this.pilotIds = [];
      this.scenario = null;
      this.activityDomain = null;
      this.comment = null;
    },
    async setArchiveDetails() {
      if (this.isSingleFlight) {
        this.canceledFlights = this.singleFlight.status === CANCELLED;
      }
      this.pilotIds = this.getPilots();
      this.scenario = this.getScenario();
      this.duration = this.getDuration();
      const { dateStart, dateEnd } = this.getDates();
      this.dateStart = (
        (dateStart === null || dateStart > this.today)
          ? this.formatDate(this.today) : this.formatDate(dateStart)
      );
      this.dateEnd = (
        (dateEnd === null || dateEnd > this.today)
          ? this.formatDate(this.today) : this.formatDate(dateEnd)
      );
      this.dates = [this.dateStart, this.dateEnd];
      await this.$store.dispatch(GET_FLIGHT_META_NS);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  &--expansion-panel {
    padding: 0;
    justify-content: flex-start;
  }

  &--select {
    align-items: flex-start;
  }
  &__text {
    margin-left: 10px;
  }
}

.duration-textfield {
  max-width: 160px;
  border-radius: 0;
}

</style>
