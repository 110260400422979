<template>
  <v-card
    class="default--dialog__card"
    elevation="0"
  >
    <v-card-title>
      <span v-translate>Update decision period</span>
    </v-card-title>
    <v-card-text>
      <!-- Period -->
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="4">
          <span v-translate>Period allowed</span>
        </v-col>
        <!-- Date start -->
        <v-col
          cols="4"
          class="pr-2"
        >
          <v-menu
            ref="menuDateStartRestriction"
            v-model="menuDateStartRestriction"
            :return-value.sync="dateStartRestriction"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateStartRestriction | date"
                append-outer-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                readonly
              />
            </template>
            <v-date-picker
              v-model="dateStartRestriction"
              locale="fr"
              first-day-of-week="1"
              scrollable
              no-title
              :max="dateEndRestriction"
            >
              <v-spacer />
              <v-btn
                text
                color="info"
                @click="menuDateStartRestriction = false"
              >
                <span v-translate>Cancel</span>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuDateStartRestriction.save(dateStartRestriction)"
              >
                <span v-translate>OK</span>
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Date end -->
        <v-col
          cols="4"
          class="pl-2"
        >
          <v-menu
            ref="menuDateEndRestriction"
            v-model="menuDateEndRestriction"
            :return-value.sync="dateEndRestriction"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateEndRestriction | date"
                append-outer-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                readonly
              />
            </template>
            <v-date-picker
              v-model="dateEndRestriction"
              locale="fr"
              first-day-of-week="1"
              scrollable
              no-title
              :min="dateStartRestriction"
            >
              <v-spacer />
              <v-btn
                text
                color="info"
                @click="menuDateEndRestriction = false"
              >
                <span v-translate>Cancel</span>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuDateEndRestriction.save(dateEndRestriction)"
              >
                <span v-translate>OK</span>
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- Time -->
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="4">
          <div v-translate>Time range allowed</div>
        </v-col>
        <v-col
          cols="4"
          class="pr-2"
        >
          <!-- Time start -->
          <TimePicker
            :time="timeStartRestriction"
            @update:time="timeStartRestriction = $event"
            dropDirection="up"
            key="timeStart"
          />
        </v-col>
        <v-col
          cols="4"
          class="pl-2"
        >
          <TimePicker
            :time="timeEndRestriction"
            @update:time="timeEndRestriction = $event"
            dropDirection="up"
            key="timeEnd"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="$emit('close')"
        :loading="loadingDecisionUpdate"
      >
        <span v-translate>Cancel</span>
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="updateDecision()"
        :loading="loadingDecisionUpdate"
      >
        <span v-translate>Confirm</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from '@/services/api';

import TimePicker from '@/components/TimePicker.vue';

export default {
  name: 'ChangeApprovalDecisionPeriod',
  components: { TimePicker },
  props: {
    flight: Object,
    decision: Object,
  },
  data() {
    const flightDateStart = this.flight.date_start;
    const flightDateEnd = this.flight.date_end;
    const flightTimeStart = this.flight.time_start;
    const flightTimeEnd = this.flight.time_end;
    return {
      flightDateStart,
      flightDateEnd,
      flightTimeStart,
      flightTimeEnd,
      menuDateStartRestriction: false,
      dateStartRestriction: this.decision.date_start || flightDateStart,
      menuDateEndRestriction: false,
      dateEndRestriction: this.decision.date_end || flightDateEnd,
      timeStartRestriction: this.decision.time_start || flightTimeStart,
      timeEndRestriction: this.decision.time_end || flightTimeEnd,
      loadingDecisionUpdate: false,
    };
  },
  methods: {
    buildPayload() {
      return {
        date_start: (
          this.flightDateStart !== this.dateStartRestriction ? this.dateStartRestriction : null
        ),
        date_end: this.flightDateEnd !== this.dateEndRestriction ? this.dateEndRestriction : null,
        time_start: (
          this.flightTimeStart !== this.timeStartRestriction
            ? this.timeStartRestriction
            : null
        ),
        time_end: this.flightTimeEnd !== this.timeEndRestriction ? this.timeEndRestriction : null,
      };
    },
    updateDecision() {
      this.loadingDecisionUpdate = true;
      const payload = this.buildPayload();
      APIService.updateApprovalDecision(this.decision.id, payload)
        .then(() => {
          this.$emit('update');
          this.showMessage(this.$gettext('Decision updated'), 'success');
        })
        .finally(() => {
          this.loadingDecisionUpdate = false;
          this.$emit('close');
        });
    },
  },
};
</script>
