<template>
  <div>
    <div class="mt-4 d-flex align-center">
      <v-icon color="info">mdi-information-outline</v-icon>
      <span class="font-italic info--text text--lighten-2 ml-2">
        <template v-if="hasManySubExploitants">
          {{ texts.explainWithManager }}
        </template>
        <template v-else>
          {{ texts.explain }}
        </template>
      </span>
    </div>
    <hr class="separator" />
    <Chat
      :events="events"
      :atSendMessage="sendMessage"
      :maxAttachmentSize="maxAttachmentSize"
      @messages-sent="(events) => $emit('messages-sent', events)"
    />
  </div>
</template>

<script>
import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB } from '@/settings';

import Chat from '@/components/Chat.vue';

const MAX_FLIGHT_DOCUMENT_SIZE_MB = MAX_DOCUMENT_SIZE_MB.flight;

export default {
  name: 'ApprovalChat',
  components: { Chat },
  props: {
    flightId: Number,
    events: Array,
  },
  data() {
    return { maxAttachmentSize: MAX_FLIGHT_DOCUMENT_SIZE_MB };
  },
  computed: {
    texts() {
      return {
        explain: this.$gettext(
          'Send message to other pilots of the mission and account administrators',
        ),
        explainWithManager: this.$gettext(
          'Send message to other pilots of the mission, account administrators and managers',
        ),
      };
    },
    hasManySubExploitants() {
      return this.$store.getters['authentication/hasManySubExploitants'];
    },
  },
  methods: {
    buildPayload(message, attachedFiles, myDocuments) {
      const payload = new FormData();
      payload.append('message', message);
      attachedFiles.forEach((file, i) => payload.append(`document_${i + 1}`, file));
      if (myDocuments.length) {
        payload.append('my_documents', JSON.stringify(myDocuments.map((d) => d.documentId)));
      }
      return payload;
    },
    async sendMessage(message, attachedFiles, myDocuments) {
      const payload = this.buildPayload(message, attachedFiles, myDocuments);
      return APIService.flightMessage(this.flightId, payload).then(({ data }) => data);
    },
  },
};
</script>
